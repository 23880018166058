import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import DesignationPieChart from 'ui-component/cards/Skeleton/DesignationPieChart';
import DomainDonutChart from 'ui-component/cards/Skeleton/DomainDonutChart';
import TechnologybarCart from 'ui-component/cards/Skeleton/TechnologybarCart';
import InHouseProjectCard from './ProjectCard';
import ResumeCard from './ResumeCard';
import TemplateCard from './TemplateCard';
import ResumeUpdatesBarChart from './ResumeUpdatesBarChart';
import { gridSpacing } from 'store/constant';
import DomainCard from './chart-data/DomainCard';
import DesignationCard from './chart-data/DesignationCard';
import TechnologyCard from './chart-data/TechnologyCard';
import AdminCard from './AdminCard';
import UserCard from './UserCard';
import { Link } from 'react-router-dom';
import { SHOW_MENU } from 'action/PermissionAction';
import { store } from 'store';

const DashBoard = () => {
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);
    const gridSpacing = 3;
    useEffect(() => {
        store.dispatch(SHOW_MENU(false));
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container justify="center">
                    <Grid item lg={3} md={6} sm={7} xs={12}>
                        <Link to={'/designations'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <DesignationCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={6} sm={7} xs={12}>
                        <Link to={'/technologies'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <TechnologyCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={6} sm={7} xs={12}>
                        <Link to={'/inhouseprojects'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <InHouseProjectCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={6} sm={7} xs={12}>
                        <Link to={'/domains'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <DomainCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={8} md={8} sm={6} xs={12}>
                        <ResumeUpdatesBarChart isLoading={isLoading} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Grid item xs={12}>
                            <DesignationPieChart isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Link to={'/resumes'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ResumeCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Link to={'/template'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <TemplateCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Link to={'/users'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <AdminCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Link to={'/users'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <UserCard isLoading={isLoading} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing} justify="center">
                    <Grid item xs={12} sm={6}>
                        <DomainDonutChart isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TechnologybarCart isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashBoard;
