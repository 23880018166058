import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useSelector } from 'react-redux';

// constant
const icons = {
    PermIdentityIcon,
    PeopleAltIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const userRoles = () => {
    // constant
    const icons = {
        PermIdentityIcon,
        PeopleAltIcon
    };

    // get permissions from redux state
    const permissions = useSelector((state) => state.fetchPermissions.permissions);
    const UserRole = {
        id: 'UserRole',
        type: 'group',
        children: []
    };

    if (permissions && permissions.includes('role.all.view')) {
        UserRole.children.push({
            id: 'role',
            title: 'Roles',
            type: 'item',
            icon: icons.PermIdentityIcon,
            url: '/roles'
        });
    }

    if (permissions && permissions.includes('user.all.view')) {
        UserRole.children.push({
            id: 'user',
            title: 'Users',
            type: 'item',
            icon: icons.PeopleAltIcon,
            url: '/users'
        });
    }

    return UserRole;
};

export default userRoles;
