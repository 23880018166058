const DomainReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_DOMAIN_SUCCESS':
            return {
                domain: action.payload,
                message: 'Success'
            };
        case 'DOMAIN_ADD_SUCCESS':
            return {
                addMessage: action.payload,
                message: 'Success'
            };
        case 'DOMAIN_ADD_FAILED':
            return {
                addMessage: action.payload,
                message: 'Failed'
            };
            case 'FETCH_DOMAIN_BY_ID_SUCCESS':
            return {
                //addMessage: action.payload,
                domain: action.payload,
                message: 'Success'
            };
        case 'FETCH_DOMAIN_BY_ID_FAILED':
            return {
                domain: action.payload,
                message: 'Failed'
            };
        case 'UPDATE_DOMAIN_SUCCESS':
            return {
                editMessage: action.payload,
                message: 'Success'
            };
        case 'UPDATE_DOMAIN_FAILED':
            return {
                editMessage: action.payload,
                message: 'Failed'
            };
        case 'DELETE_DOMAIN_SUCCESS':
            return {
                editMessage: action.payload,
                message: 'Success'
            };
        case 'DELETE_DOMAIN_FAILED':
            return {
                editMessage: action.payload,
                message: 'Failed'
            };
            case 'RESET_DOMAIN':
            return {
                //addMessage: action.payload,
                domain: {},
                message: 'Success'
            };
        default:
            return state;
    }
};

export default DomainReducer;
