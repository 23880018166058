import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NotFound from 'views/errorResponse/NotFound';
import { showSnackbar } from 'action/SnackbarAction';
import { useDispatch } from 'react-redux';
import CustomToggleButton from 'ui-component/extended/CustomToggleButton';
import { FormControl, InputLabel } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import apiEndPoints from '../../../../src/constants/api';
const { hostname } = apiEndPoints;
import axios from 'axios';
import { Logout } from '@mui/icons-material';
import useAxiosInterceptors from '../authentication/auth-forms/AxiosConn';
import { checkPermissions } from '../Permissions/CheckPermissions';

const Profile = () => {
    const accessToken = localStorage.getItem('token');
    //const accessToken = tokens.accessToken;
    console.log(accessToken);

    const details = JSON.parse(localStorage.getItem('details'));
    console.log('details', details);
    const [userId, setUserId] = useState(details.userId);
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [HasPassword, setHasPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isActive, setIsActive] = useState();
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const axiosConn = useAxiosInterceptors();
    const editPermisiion = checkPermissions('user.profile.edit');

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
        //Api call to match with db
    };

    const handlePaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^[a-zA-Z0-9\s-]*$/;
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('Special characters not allowed');
            // setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^[a-zA-Z0-9\s-]*$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
        if (!regex.test(inputString)) {
            event.preventDefault();
            // setNameError('special characters not allowed');
            // setIsNameValid(false);
        } else {
            // setNameError('');
            setIsNameValid(true);
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };

    const handlePwdPaste = (event) => {
        const inputString = event.clipboardData.getData('Text');
        const regex = /^\S+$/;
        if (!regex.test(inputString)) {
            event.preventDefault();
            setPwdError('Password should not contain spaces');
            setIsPwdValid(false);
        } else {
            setPwdError('');
            setIsPwdValid(true);
        }
    };
    const handlePwdKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        const regex = /^\S+$/;
        const inputString = event.target.value + String.fromCharCode(event.charCode);
        if (!regex.test(inputString)) {
            event.preventDefault();
            setPwdError('Password should not contain spaces');
            setIsPwdValid(false);
        } else {
            setPwdError('');
            setIsPwdValid(true);
        }
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateUser(userId, firstname, lastname, email, oldPassword, password, confirmPassword);
    };

    const logout = async () => {
        try {
            const res = await axiosConn.post(`${hostname}/api/v1/Token/logout`);
            window.localStorage.clear();
            window.location.replace('/');
            // navigate('/');
        } catch (err) {}
    };

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    };

    useEffect(() => {
        if (id == userId) {
            setIsValid(true);
        }
    }, []);

    useEffect(() => {
        const getUser = async () => {
            //             axios.get(url, config)
            //   .then(res=> console.log(res))
            //   .catch(err=> console.log(err))

            // debugger;
            const res = await axios
                .get(`${hostname}/api/v1/User/profile?UserId=${userId}`, config)
                .then((res) => {
                    console.log(res, 'res');
                    console.log(res.status, 'res.status');
                    setUserId(userId);
                    setFirstName(res.data.firstName);
                    setLastName(res.data.lastName);
                    setEmail(res.data.email);
                    setHasPassword(res.data.hasPassword);
                })
                .catch((error) => {
                    if (error.response.status == 400) {
                        const newErrors = error.response.data.errors.map((error) => error.message);
                        setError(newErrors);
                        console.log(newErrors);
                    }
                    console.log('catch', error.response.status);
                });
        };
        getUser();
    }, []);

    const updateUser = async (userId, firstName, lastName, email, oldPassword, password, confirmPassword) => {
        try {
            // debugger;
            const res = await axiosConn.post(`${hostname}/api/v1/User/profile`, {
                userId: userId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                oldPassword: oldPassword,
                password: password,
                confirmPassword: confirmPassword
            });
            console.log(res, 'res');
            console.log(res.status, 'res.status');

            if (res.status == 200) {
                console.log('status:200');
                const details = { userId, firstName, lastName, email };
                window.localStorage.setItem('details', JSON.stringify(details));
                navigate('/dashboard');
                //logout();
            } else {
                console.log('status=400, res.data.data', res);

                // if (res.status == 400) {
                // const newErrors = res.errors.map((error) => error.message);
                // setError(newErrors);
                // console.log('status=400', res);
                // } else {
                // setError(['Something went wrong']);
                // console.log('else of status=400', res);
                // }
            }
        } catch (error) {
            if (error.response.status == 400) {
                const newErrors = error.response.data.errors.map((error) => error.message);
                setError(newErrors);
                console.log(newErrors);
            } else {
                setError(['Something went wrong']);
            }
            console.log('catch', error.response.status);
        }
    };

    return (
        <>
            {isValid == true ? (
                <Box mt={10}>
                    <Grid container justifyContent="center" mt={3}>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={6}
                            lg={6}
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                p: 7,
                                bgcolor: 'white'
                            }}
                        >
                            <Typography variant="h2" mb={5}>
                                Profile settings
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <TextField
                                    id="FirstName"
                                    label="First name"
                                    variant="outlined"
                                    mb={2}
                                    sx={{ width: '100%' }}
                                    autoComplete="off"
                                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}
                                    onChange={handleFirstNameChange}
                                    value={firstname}
                                />
                                <TextField
                                    id="LastName"
                                    label="Last name"
                                    variant="outlined"
                                    mb={2}
                                    sx={{ width: '100%' }}
                                    autoComplete="off"
                                    value={lastname}
                                    // InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}

                                    onChange={handleLastNameChange}
                                    //onKeyDown={handleKeyDown}
                                />
                                <TextField
                                    id="Email"
                                    label="Email"
                                    variant="outlined"
                                    mb={2}
                                    sx={{ width: '100%' }}
                                    autoComplete="off"
                                    value={email}
                                    //InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste } }}
                                    disabled={true}
                                    onChange={handleEmailChange}
                                    //onKeyDown={handleKeyDown}
                                />
                                {/* {HasPassword == true ? (
                                    <FormControl sx={{ width: '100%' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-oldPassword">Current password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-oldPassword"
                                            type={showOldPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle oldPassword visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="OldPassword"
                                            value={oldPassword}
                                            onKeyPress={handlePwdKeyPress}
                                            onPaste={handlePwdPaste}
                                            onChange={handleOldPasswordChange}
                                        />
                                    </FormControl>
                                ) : (
                                    <></>
                                )}

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        onKeyPress={handlePwdKeyPress}
                                        onPaste={handlePwdPaste}
                                    />
                                </FormControl>

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-confirmpassword">Confirm password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirmpassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirmpassword visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="confirmpassword"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        onKeyPress={handlePwdKeyPress}
                                        onPaste={handlePwdPaste}
                                    />
                                </FormControl> */}

                                {error ? (
                                    <>
                                        {error.length > 0 && (
                                            <Box sx={{ color: '#FF0000', fontSize: '0.8rem', marginLeft: '-4%' }}>
                                                <ul>
                                                    {error.map((errors, index) => (
                                                        <li key={index}>{errors}</li>
                                                    ))}
                                                </ul>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, gap: 2, mt: 4 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ color: 'white' }}
                                    onClick={(e) => {
                                        handleSubmit(e);
                                    }}
                                    disabled={!editPermisiion}
                                >
                                    Save
                                </Button>

                                <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={(e) => navigate('/dashboard')}>
                                    Cancel
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <NotFound />
            )}
        </>
    );
};
export default Profile;
