import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MENU_OPEN, SET_MENU } from 'store/actions';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button, AppBar, Toolbar, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { color, fontSize } from '@mui/system';
import { getBasicInfo, deleteResume, getDesignations } from 'action/ResumeAction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PinDropIcon from '@mui/icons-material/PinDrop';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Mail, Phone, Work } from '@mui/icons-material';
import { store } from 'store';
import { SHOW_MENU } from 'action/PermissionAction';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import { setLoading } from 'action/SpinnerActions';
import apiEndPoints from '../../../../src/constants/api';
import ResumeDownloadModal from 'views/pages/admin/TemplatePreview/ResumeDownloadModal';
import { checkPermissions } from 'views/pages/Permissions/CheckPermissions';
import PropTypes from "prop-types";
const { hostname } = apiEndPoints;
const useStyles = makeStyles((theme) => ({
    dashboard: {
        height: '100%',
        marginTop: '30px'
    },
    dashboardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',

        padding: '10px',

        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px'
    },
    createNew: {
        position: 'relative',
        '&:hover .overlayIcon': {
            color: '#f00'
        }
    },
    overlay: {
        width: '300px',
        height: '400px',
        border: '2px solid #5E35B1',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        transition: 'all 0.3s ease',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'none',
            width: '285px',
            height: '385px',
            transition: 'all 0.3s ease'
        }
    },

    overlayIcon: {
        color: '#fff',
        fontSize: '3rem',
        transition: 'all 0.3s ease'
    },
    overlayIconHover: {
        color: '#f00'
    },
    edit: {
        backgroundColor: 'white',
        padding: '8px',
        marginRight: '10px'
    },
    delete: {
        backgroundColor: 'white',
        padding: '8px',
        marginLeft: '10px'
    },
    resumeEdit: {
        width: '300px',
        height: '400px',
        border: '2px solid #5E35B1',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        borderRadius: '10px',
        transition: 'all 0.3s ease',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'none',
            width: '285px',
            height: '385px',
            transition: 'all 0.3s ease'
        }
    },
    userDetailCard: {
        margin: 'auto',
        width: '70%',
        backgroundColor: '#f8f8f8'
    },
    userName: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: '20px',
        marginBottom: '20px'
    },
    userDetailTitle: {
        padding: '8px 20px 0px 20px',
        borderRadius: '8px 8px 0 0',
        backgroundColor: '#6638B3',

        display: 'flex',
        alignItems: 'baseline'
    },
    icongroup: {},
    texticon: {
        display: 'flex'
    }
}));

function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex" left={170} top={13}>
        <CircularProgress variant="determinate" thickness={5} style={{'color': 'white'}} {...props} />
        <Box
          top={0}
          left={20}
          bottom={0}
          right={20}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            color='white'
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
  };

const index = () => {
    const dispatch = useDispatch();
    const axiosConn = useAxiosInterceptors();
    const classes = useStyles();
    const details = JSON.parse(localStorage.getItem('details'));
    localStorage.setItem('userId', details.userId);
    const resumeiddownload = JSON.parse(localStorage.getItem('resumeId'))
    const resumeId = useSelector((state) => state.getResumeId.resumeId) || [];
    console.log('resumeIdresumeId', resumeId);
    const basicInfoData = useSelector((state) => state.basicInfo.basicInfo) || [];
    console.log('resumeId', resumeId);
    const designationSelect = useSelector((state) => state.designationList.designations.data) || [];
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [basicInfoState, setBasicInfoState] = useState({});
    const isLoading = useSelector((state) => state.basicInfo.loading) || [];

    const designationName = (id) => designationSelect.find((designation) => designation.id === id)?.name;

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_MENU, opened: true });
    };
    const isResumeDownloadAllowed = checkPermissions('resume.download');
    const userResumeId = localStorage.getItem('resumeId');
    useEffect(() => {
        itemHandler('default');
    }, []);

    useEffect(() => {
        // dispatch(getDesignations());
        dispatch({ type: 'GET_DESIGNATIONS_REQUEST' });
        axiosConn
            .get(`${hostname}/api/v1/designation/all`)
            .then((response) => {
                dispatch({ type: 'GET_DESIGNATIONS_SUCCESS', payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: 'GET_DESIGNATIONS_FAILURE', payload: error.message });
            });
        // dispatch(getBasicInfo(localStorage.getItem('resumeId')));
        dispatch({ type: 'GET_BASIC_INFO_REQUEST' });
        dispatch(setLoading(true));
        axiosConn
            .get(`${hostname}/api/v1/resume/basicinfo?ResumeId=${localStorage.getItem('resumeId')}`)
            .then((response) => {
                dispatch({ type: 'GET_BASIC_INFO_SUCCESS', payload: response.data });
                localStorage.setItem('basicInfo', JSON.stringify(response.data));
                setBasicInfoState(response.data);
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: 'GET_BASIC_INFO_FAILURE', payload: error.message });
            });
    }, [dispatch]);

    useEffect(() => {
        // if (basicInfoData.id && basicInfoState.id != '0') {
        //     localStorage.setItem('resumeId', 0);
        // }
        setBasicInfoState[basicInfoData];
    }, [basicInfoData]);

    useEffect(() => {
        store.dispatch(SHOW_MENU(false));
    }, []);

    const downloadResume = async (resumeId, Name) => {
        try {
            const res = await axiosConn.get(`${hostname}/api/v1/Resume/DownloadResumeData/${resumeId}`, {
                responseType: 'blob' // set response type to blob
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Name}.docx`);
            console.log('filename', Name); // set file name and type
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log('not downloading.....', error);
        }
    };

    const handleSubmit = (event, resumeId, Name) => {
        console.log('Resume ID:', resumeId);
        downloadResume(resumeId, Name);
    };
    console.log('basicInfoState', basicInfoData);
    
    const handleTooltipOpen = () => {
        if (isResumeDownloadAllowed) {
          setTooltipOpen(true);
        }
      };

      const handleTooltipClose = () => {
        setTooltipOpen(false);
      };
  
    return (
        <Box className={classes.dashboard}>
            {basicInfoData.id && basicInfoData.id != '0' ? (
                <Paper className={classes.userDetailCard}>
                    <Box className={classes.userDetailTitle}>
                        <Typography variant="h6" className={classes.userName} sx={{ color: 'white' }}>
                            {`${basicInfoData.firstname} ${basicInfoData.lastname}`}
                        </Typography>
                        <CircularProgressWithLabel value={basicInfoData.percentageCompleted} />
                        <Link to={`/basicinfo/${basicInfoData.id}`} style={{ textDecoration: 'none' }}>
                           
                                <IconButton>
                                    <EditIcon sx={{ fontSize: '20px', color: 'white', marginBottom: '10px' }} />
                                </IconButton>
                        </Link>
                       
                            <IconButton  >
                                {isResumeDownloadAllowed && (
                                    <ResumeDownloadModal
                                        resumeId={userResumeId}
                                        name={`${basicInfoData.firstname} ${basicInfoData.lastname}`}
                                        color="white"
                                    />
                                )}
                            </IconButton>
                    
                    </Box>
                    <Grid container spacing={2} sx={{ padding: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.texticon}>
                                <PinDropIcon sx={{ color: '#8b8e8e' }} />
                                &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" sx={{ color: '#8b8e8e' }}>
                                    {`${basicInfoData.city || ''}, ${basicInfoData.state || ''}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.texticon}>
                                <LocalPhoneIcon sx={{ color: '#8b8e8e' }} />
                                &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" sx={{ color: '#8b8e8e' }}>
                                    {basicInfoData.phoneNumber || ''}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.texticon}>
                                <WorkOutlineIcon sx={{ color: '#8b8e8e' }} />
                                &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" sx={{ color: '#8b8e8e' }}>
                                    {designationName(basicInfoData.designationId) || ''}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.texticon}>
                                <MailOutlineIcon sx={{ color: '#8b8e8e' }} />
                                &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" sx={{ color: '#8b8e8e' }}>
                                    {basicInfoData.email || ''}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Box>
                    {isLoading == false ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
                            {/* {localStorage.setItem('resumeId', 0)} */}
                            <Box className={classes.overlay}>
                                <LibraryAddIcon className={classes.overlayIcon} sx={{ fontSize: 40 }} />

                                <Link to="/basicinfo" style={{ textDecoration: 'none' }}>
                                    <Button variant="contained" color="secondary" sx={{ color: 'white', marginTop: '20px' }}>
                                        Create Resume
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    ) : (
                        <Box mt={35} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color="secondary.main">
                            <CircularProgress disableShrink />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default index;
