import React from 'react'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useSelector } from 'react-redux';

const UploadFile = () =>{
    const icons ={
        ArticleOutlinedIcon
    };
    // get permissions from redux state
    const permissions = useSelector((state) => state.fetchPermissions.permissions);
    const uploadFile = {
        id: 'upload',
        type: 'group',
        children: []
    };
    if(permissions && permissions.includes('inputTemplate.all.view'))
    {
        uploadFile.children.push({
            id: 'upload file',
            title: 'Templates',
            type: 'item',
            icon: icons.ArticleOutlinedIcon,
            url: '/template'
        });
    }
  return uploadFile
}

export default UploadFile;