import dashboard from './dashboard';
// import pages from './pages';
//import { resumeCreateSections, resumeEditSections } from './resumeSections';
// ==============================|| MENU ITEMS ||============================== //
// function getItems() {
//     const basicInfoData = useSelector((state) => state.basicInfo.basicInfo) || [];
//     return basicInfoData.id != null ? [dashboard, resumeEditSections] : [dashboard, resumeCreateSections];
// }
// const items = localStorage.getItem('isBasicInfoExist') === 'true' ? [dashboard, resumeEditSections] : [dashboard, resumeCreateSections];

const menuItems = {
    items: [dashboard]
};

export default menuItems;
