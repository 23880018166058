import PropTypes from 'prop-types';
import * as React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, Typography } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
// assets
import { IconMenu2 } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';
import { store } from 'store';
import { SHOW_MENU } from 'action/PermissionAction';
import { RESET_TOKEN } from 'action/TokenAction';
import apiEndPoints from '../../../../src/constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import logo from 'assets/images/Logo.png';
import { RESET_BASIC_INFO_STATE } from 'action/ResumeAction';
const { hostname } = apiEndPoints;

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const userData = JSON.parse(localStorage.getItem('details'));
    const axiosConn = useAxiosInterceptors();
    const id = userData?.userId || '';
    const fname = userData?.firstName || '';
    const lname = userData?.lastName || '';
    const email = userData?.email || '';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = async () => {
        try {
            const res = await axiosConn.post(`${hostname}/api/v1/Token/logout`);
            window.localStorage.removeItem('isLoggedIn');
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('Permissions');
            window.localStorage.removeItem('isAdmin');
            localStorage.clear();
            store.dispatch(SHOW_MENU(false));
            dispatch(RESET_BASIC_INFO_STATE());
            dispatch(RESET_TOKEN());

            handleClose();
            navigate('/');
        } catch (err) {}
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 270,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block', md: 'block' }, flexGrow: 1, marginLeft: '-12px', zIndex: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}

            <Box sx={{ flexGrow: 1, textAlign: 'center',marginLeft:35 }}>
                <img src={logo} alt="quick Logo" height={50} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 62,
                            height: 62,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to={`/profile/${id}`} state={{ userDetails: userData }} style={{ textDecoration: 'none' }}>
                        <ListItemIcon>
                            <AccountCircleOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {isLoggedIn && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip
                        sx={{ background: theme.palette.secondary.light, color: theme.palette.secondary.dark }}
                        title={
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {fname} {lname}
                                </Typography>
                                <Typography gutterBottom>{email}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            onClick={handleClick}
                            variant="text"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '25px',
                                '&:hover': {
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark
                                }
                            }}
                        >
                            {/*header logout button */}
                            <Avatar sx={{ bgcolor: theme.palette.secondary.dark, color: 'white', width: 35, height: 35 }}>
                                {fname.slice(0, 1)}
                            </Avatar>
                        </Button>
                    </Tooltip>
                </Box>
            )}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
