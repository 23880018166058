import SkeletonTechnologyPieCart from 'views/pages/admin/DashBoard/TechnologybarChart';
import { Box, Typography } from '@mui/material';

const TechnologybarCart = () => {
    return (
        <Box bgcolor="white" sx={{ borderRadius: '1rem' }}>
            <Typography variant="h4" textAlign="center" sx={{ p: 5 }}>
                {' '}
                Top 10 technologies used
            </Typography>
            <SkeletonTechnologyPieCart />
        </Box>
    );
};
export default TechnologybarCart;
