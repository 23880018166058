import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
    useEffect(() => {
        window.addEventListener('storage', function (event) {
            if (event.key === 'isLoggedIn' && !localStorage.getItem('isLoggedIn')) {
                // Redirect to the login page if logged out in another tab
                window.location.replace('/');
            }
        });
        // window.addEventListener('storage', function (event) {
        //     if (event.key === 'persist:root') {
        //         // Redirect to the login page if logged out in another tab
        //         window.location.reload();
        //     }
        // });
        window.addEventListener('storage', function (event) {
            if (event.key === 'isLoggedIn' && localStorage.getItem('isLoggedIn') == 'true') {
                // Redirect to the login page if logged out in another tab
                window.location.replace('/dashboard');
            }
        });
    }, []);
    const customization = useSelector((state) => state.customization);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
