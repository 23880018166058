import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Button, Chip, CircularProgress, Divider, Grid, TablePagination, TableSortLabel, TextField, Typography } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Box, Stack } from '@mui/system';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
//import axios from 'axios';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { checkPermissions } from 'views/pages/Permissions/CheckPermissions';
import { store } from 'store';
import { SHOW_MENU } from 'action/PermissionAction';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import apiEndPoints from '../../../../constants/api';
const { hostname } = apiEndPoints;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function Template() {
    const [template, setTemplate] = useState();
    const [templateList, setTemplateList] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(window.localStorage.getItem('TemplateRowsPerPage') || 10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isAscending, setIsAscending] = useState(true);
    const [sortBy, setSortBy] = useState('name');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const axiosConn = useAxiosInterceptors();
    const [page, setPage] = useState(() => {
        return parseInt(localStorage.getItem('templateCurrentPage') || 0);
    });
    const setdata = (res) => {
        const sort = JSON.parse(localStorage.getItem('templatesorting')) || {};
        if (Object.keys(sort).length > 0) {
            handleSortClick(res, sort.columnName, sort.isAscending);
        } else {
            setTemplateList(res);
        }
        setIsLoading(false);
        setTotalRecords(res.length);
    };

    const addpermission = checkPermissions('inputTemplate.add');
    const updatepermission = checkPermissions('inputTemplate.edit');
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.setItem('templateCurrentPage', page.toString());
    }, [page]);

    useEffect(() => {
        store.dispatch(SHOW_MENU(false));
    }, []);

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                
                    const res = await axiosConn.get(`${hostname}/api/v1/Template/List`);
                    setdata(res.data.data);
                    setIsLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchTemplate();
    }, []);

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        window.localStorage.setItem('TemplateRowsPerPage', newRowsPerPage);
        setPage(0);
    };

    const handlePage = (event, newPage) => {
        setPage(newPage);
        window.localStorage.setItem('templateCurrentPage', newPage);
    };

    const handleSearchPage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleSearchClick = () => {
    //     setPage(0);
    //     console.log('clicked search button', searchValue);
    //     if (searchValue != null) setSearch(searchValue);
    // };

    // const handleClear = () => {
    //     console.log('in handle clear', searchValue);
    //     setSearchValue('');
    //     console.log('in handle clear after clearing', searchValue);
    //     setSearch('');
    // };

    // const handleKeyDown = (event) => {
    //     setPage(0);
    //     if (event.keyCode === 13 && searchValue !== '') {
    //         event.preventDefault();
    //         setSearch(searchValue);
    //         console.log('handle key down', searchValue);
    //     } else if (event.keyCode === 13 && searchValue == '') {
    //         event.preventDefault();
    //         setSearch('');
    //         console.log('handle key down', search);
    //     }
    // };

    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;

        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };
    const handleSortClick = (templateData, columnName, isAscending) => {
        setSortBy(columnName);
        setIsAscending(isAscending);

        const sortedTemplateList = [...templateData];

        sortedTemplateList.sort((a, b) => {
            if (!isAscending) {
                [a, b] = [b, a];
            }

            if (columnName === 'name') {
                return a.name.localeCompare(b.name);
            }
        });

        setTemplateList(sortedTemplateList);
        localStorage.setItem('templatesorting', JSON.stringify({ columnName, isAscending }));
    };

    console.log('totalRecords', totalRecords);
    return (
        <Box mt={7}>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                {isLoading ? (
                    <Typography mt={10} color="secondary.main">
                        <CircularProgress />
                    </Typography>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    background: 'transparent',
                                    position: 'relative'
                                }}
                            >
                                <Grid container direction="row" justifyContent="space-between" mb="3%">
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="dark"
                                            size="large"
                                            disabled={!addpermission}
                                            sx={{ display: 'flex', alignItems: 'center', gap: '5px', maxWidth: '90px' }}
                                            onClick={(e) => navigate('/inputTemplate')}
                                        >
                                            <AddCircleSharpIcon /> <span>New</span>
                                        </Button>
                        </div>
                                        
                                </Grid>
                            </Box>
                            {totalRecords !== 0 ? (
                                <>
                                    <Box
                                        sx={{
                                            background: 'transparent',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                    >
                                        <Paper zIndex="1" direction="row" sx={{ minWidth: '53%' }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>
                                                                <TableSortLabel
                                                                    active={sortBy === 'name'}
                                                                    direction={isAscending ? 'asc' : 'desc'}
                                                                    onClick={() => handleSortClick(templateList, 'name', !isAscending)}
                                                                    sx={{
                                                                        color: 'secondary.light',
                                                                        '&:hover': {
                                                                            color: 'secondary.light'
                                                                        }
                                                                    }}
                                                                >
                                                                    Template
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell>

                                                            </StyledTableCell>
                                                            
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {templateList.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((template) => {
                                                            return (
                                                                <StyledTableRow>
                                                                    <StyledTableCell component="th" scope="row" sx={{ width: 270 }}>
                                                                        {template.name}
                                                                    </StyledTableCell>
                                                                 
                                                                    <StyledTableCell>
                                                                        <Button
                                                                            color="dark"
                                                                            disabled={!updatepermission}
                                                                            onClick={(e) => navigate(`/inputTemplate/${template.id}`)}
                                                                        >
                                                                            <BorderColorIcon />
                                                                        </Button>
                                                                    </StyledTableCell>
                                                                    
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                <Divider />
                                                { rowsPerPage && totalRecords && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        component="div"
                                                        count={totalRecords}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleSearchPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                )}
                                                {/* {!search ? (
                                                    <>
                                                        {rowsPerPage && totalRecords && (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                component="div"
                                                                count={totalRecords}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handlePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}{' '} */}
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            background: 'transparent',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                    >
                                        <Paper zIndex="1" direction="row" sx={{ minWidth: '53%' }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell sx={{ width: '46%' }}>Template</StyledTableCell>
                                                            
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={9} sx={{ width: '585px' }}>
                                                                <Typography
                                                                    style={{ color: '#ff0000' }}
                                                                    fontWeight="bold"
                                                                    sx={{ ml: '5%' }}
                                                                >
                                                                    No records found
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
