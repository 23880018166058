import React from 'react';
import { Box, Typography } from '@mui/material';

import SkeletonDesignationPieChart from 'views/pages/admin/DashBoard/DesignationPieChart';

const DesignationPieChart = () => {
    return (
        <Box bgcolor="white" sx={{ borderRadius: '1rem' }}>
            <Typography variant="h4" textAlign="center" sx={{ p: 5 }}>
                Top 10 designations used
            </Typography>
            <SkeletonDesignationPieChart />
        </Box>
    );
};

export default DesignationPieChart;
