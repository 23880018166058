export default {
    // The URL we're connecting to
    hostname: 'https://quickcv.sakhaglobal.com',

    // Users API
    users: {
        endpoint: 'api/v1/users',
        deleteUser: (id) => `api/v1/users/${id}`,
        getUser: (id) => `api/v1/users/${id}`,
        forgotPass: 'api/accounts/password/forgot',
        sendCode: 'api/token/code/generate'
    },

    // Education APIs
    educationEP: {
        endpoint: 'api/v1/resume/education',
        getAll: (id) => `api/v1/resume/education?ResumeId=${id}`,
        get: (id) => `api/v1/resume/education/${id}`,
        update: (id) => `api/v1/resume/education/${id}`,
        delete: (id) => `api/v1/resume/education/${id}`
    },
    abilitiesEP: {
        endpoint: 'api/v1/Resume/Abilities',
        getAll: (id) => `api/v1/resume/Abilities?ResumeId=${id}`,
        get: (id) => `api/v1/resume/Abilities/${id}`,
        update: (id) => `api/v1/resume/Abilities/${id}`,
        delete: (id) => `api/v1/resume/Abilities/${id}`
    },
    certificationEP: {
        endpoint: 'api/v1/resume/certificate',
        getAll: (id) => `api/v1/resume/certificate?ResumeId=${id}`,
        get: (id) => `api/v1/resume/certificate/${id}`,
        update: (id) => `api/v1/resume/certificate/${id}`,
        delete: (id) => `api/v1/resume/certificate/${id}`
    },

    // In-house Projects APIs
    inHouseProjectsEP: {
        endpoint: 'api/v1/resume/inhouseexperience',
        getAll: (id) => `api/v1/resume/inhouseexperience?ResumeId=${id}`,
        get: (id) => `api/v1/resume/inhouseexperience/${id}`,
        update: (id) => `api/v1/resume/inhouseexperience/${id}`,
        delete: (id) => `api/v1/resume/inhouseexperience/${id}`
    },

    // Other Project APIs
    otherProjectsEP: {
        endpoint: 'api/v1/resume/otherexperience',
        getAll: (id) => `api/v1/resume/otherexperience?ResumeId=${id}`,
        get: (id) => `api/v1/resume/otherexperience/${id}`,
        update: (id) => `api/v1/resume/otherexperience/${id}`,
        delete: (id) => `api/v1/resume/otherexperience/${id}`
    },

    // Basic Info APIs
    basicInfo: {
        endpoint: 'api/v1/resume/basicinfo',
        get: (id) => `api/v1/resume/basicinfo?ResumeId=${id}`,
        update: (id) => `api/v1/resume/basicinfo/${id}`
    },
    // Resume APIs
    resume: {
        endpoint: 'api/v1/resume',
        get: (id) => `api/v1/resume/${id}`,
        delete: (id) => `api/v1/resume/${id}`
    },
    qualification: {
        endpoint: 'api/v1/Qualification',
        getActive: 'api/v1/Qualification/Active'
    },
    skillCategory: {
        endpoint: 'api/v1/AbilitiesCategory/All',
        
    },
    technology: {
        endpoint:'api/v1/Technology',
        getActive: 'api/v1/Technology/Active',
        
    }
};
