import DomainReducer from 'reducers/DomainReducer';
import SnackbarReducer from 'reducers/SnackbarReducer';
import TechnologyReducer from 'reducers/TechnologyReducer';
import { combineReducers } from 'redux';
import {
    designationReducer,
    basicInfoReducer,
    educationReducer,
    abilitiesReducer,
    inHouseProjectReducer,
    otherProjectReducer,
    resumeReducer,
    inHouseProjectsForSelectReducer,
    allTechnologyReducer,
    allProjectDomainReducer,
    fetchResumeIdReducer,
    getResumeIdReducer,
    certificationReducer
} from 'reducers/ResumeReducer';
// reducer import
import customizationReducer from './customizationReducer';
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer
} from './userReducers';
//import PermissionsReducer from 'reducers/PermissionsReducer';
import PermissionReducer from 'reducers/PermissionsReducer';
import ShowMenuReducer from 'reducers/ShowMenuReducer';
import TokenReducer from 'reducers/TokenReducer';
import spinnerReducer from 'reducers/SpinnerReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    adminDomain: DomainReducer,
    adminTechnology: TechnologyReducer,
    customSnackbar: SnackbarReducer,
    inHouseProjectInfo: inHouseProjectReducer,
    otherProjectInfo: otherProjectReducer,
    basicInfo: basicInfoReducer,
    educationInfo: educationReducer,
    abilitiesInfo:abilitiesReducer,
    designationList: designationReducer,
    resumeData: resumeReducer,
    inHouseProjectsForSelect: inHouseProjectsForSelectReducer,
    technologiesForSelect: allTechnologyReducer,
    projectDomainForSelect: allProjectDomainReducer,
    fetchResumeIdReducer: fetchResumeIdReducer,
    fetchPermissions: PermissionReducer,
    showMenuPermissions: ShowMenuReducer,
    getResumeId: getResumeIdReducer,
    tokenReducer: TokenReducer,
    spinner: spinnerReducer,
    certificates: certificationReducer
});

export default reducer;
