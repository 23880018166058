import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import apiEndPoints from '../../../../../src/constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import SkeletonCard from 'ui-component/cards/Skeleton/SkeletonCard';
const { hostname } = apiEndPoints;

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative'
}));

const UserCard = ({ isLoading }) => {
    const theme = useTheme();
    const [usercount, setUserCount] = useState([]);
    const axiosConn = useAxiosInterceptors();

    useEffect(() => {
        if (!isLoading) {
            axiosConn
                .get(`${hostname}/api/v1/Dashboard/UserCount`)
                .then((response) => {
                    setUserCount(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [isLoading]);
    return (
        <>
            {isLoading ? (
                <SkeletonCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={
                                        <Typography variant="h4" sx={{ color: theme.palette.common.white }}>
                                            {usercount.length > 0 ? usercount : 0}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: theme.palette.common.white,
                                                mt: 0.5,
                                                fontWeight: 'lighter'
                                                //fontSize:'15px'
                                            }}
                                        >
                                            Users
                                        </Typography>
                                    }
                                />
                                <ListItemAvatar>
                                    <PeopleAltIcon sx={{ color: theme.palette.common.white, fontSize: '40px' }} />
                                </ListItemAvatar>
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

UserCard.propTypes = {
    isLoading: PropTypes.bool
};

export default UserCard;
