import axios from 'axios';
import apiEndPoints from '../constants/api';
const { hostname, users, educationEP, abilitiesEP,inHouseProjectsEP, otherProjectsEP, certificationEP, basicInfo, resume } = apiEndPoints;
const token = window.localStorage.getItem('token');

export const GET_DESIGNATIONS_REQUEST = 'GET_DESIGNATIONS_REQUEST';
export const GET_DESIGNATIONS_SUCCESS = 'GET_DESIGNATIONS_SUCCESS';
export const GET_DESIGNATIONS_FAILURE = 'GET_DESIGNATIONS_FAILURE';

export const SAVE_BASIC_INFO_REQUEST = 'SAVE_BASIC_INFO_REQUEST';
export const SAVE_BASIC_INFO_SUCCESS = 'SAVE_BASIC_INFO_SUCCESS';
export const SAVE_BASIC_INFO_FAILURE = 'SAVE_BASIC_INFO_FAILURE';

export const GET_BASIC_INFO_REQUEST = 'GET_BASIC_INFO_REQUEST';
export const GET_BASIC_INFO_SUCCESS = 'GET_BASIC_INFO_SUCCESS';
export const GET_BASIC_INFO_FAILURE = 'GET_BASIC_INFO_FAILURE';

export const UPDATE_BASIC_INFO_REQUEST = 'UPDATE_BASIC_INFO_REQUEST';
export const UPDATE_BASIC_INFO_SUCCESS = 'UPDATE_BASIC_INFO_SUCCESS';
export const UPDATE_BASIC_INFO_FAILURE = 'UPDATE_BASIC_INFO_FAILURE';

export const SAVE_EDUCATION_REQUEST = 'SAVE_EDUCATION_REQUEST';
export const SAVE_EDUCATION_SUCCESS = 'SAVE_EDUCATION_SUCCESS';
export const SAVE_EDUCATION_FAILURE = 'SAVE_EDUCATION_FAILURE';

export const GET_EDUCATION_REQUEST = 'GET_EDUCATION_REQUEST';
export const GET_EDUCATION_SUCCESS = 'GET_EDUCATION_SUCCESS';
export const GET_EDUCATION_FAILURE = 'GET_EDUCATION_FAILURE';

export const GET_ALL_EDUCATION_REQUEST = 'GET_ALL_EDUCATION_REQUEST';
export const GET_ALL_EDUCATION_SUCCESS = 'GET_ALL_EDUCATION_SUCCESS';
export const GET_ALL_EDUCATION_FAILURE = 'GET_ALL_EDUCATION_FAILURE';

export const UPDATE_EDUCATION_REQUEST = 'UPDATE_EDUCATION_REQUEST';
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS';
export const UPDATE_EDUCATION_FAILURE = 'UPDATE_EDUCATION_FAILURE';

export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';

//Skill
export const SAVE_ABILITIES_REQUEST = 'SAVE_ABILITIES_REQUEST';
export const SAVE_ABILITIES_SUCCESS = 'SAVE_ABILITIES_SUCCESS';
export const SAVE_ABILITIES_FAILURE = 'SAVE_ABILITIES_FAILURE';

export const GET_ABILITIES_REQUEST = 'GET_EDUCATION_REQUEST';
export const GET_ABILITIES_SUCCESS = 'GET_ABILITIES_SUCCESS';
export const GET_ABILITIES_FAILURE = 'GET_ABILITIES_FAILURE';

export const GET_ALL_ABILITIES_REQUEST = 'GET_ALL_ABILITIES_REQUEST';
export const GET_ALL_ABILITIES_SUCCESS = 'GET_ALL_ABILITIES_SUCCESS';
export const GET_ALL_ABILITIES_FAILURE = 'GET_ALL_ABILITIES_FAILURE';

export const UPDATE_ABILITIES_REQUEST = 'UPDATE_ABILITIES_REQUEST';
export const UPDATE_ABILITIES_SUCCESS = 'UPDATE_ABILITIES_SUCCESS';
export const UPDATE_ABILITIES_FAILURE = 'UPDATE_ABILITIES_FAILURE';

export const DELETE_ABILITIES_REQUEST = 'DELETE_ABILITIES_REQUEST';
export const DELETE_ABILITIES_SUCCESS = 'DELETE_ABILITIES_SUCCESS';
export const DELETE_ABILITIES_FAILURE = 'DELETE_ABILITIES_FAILURE';

export const SAVE_CERTIFICATION_REQUEST = 'SAVE_CERTIFICATION_REQUEST';
export const SAVE_CERTIFICATION_SUCCESS = 'SAVE_CERTIFICATION_SUCCESS';
export const SAVE_CERTIFICATION_FAILURE = 'SAVE_CERTIFICATION_FAILURE';

export const GET_CERTIFICATION_REQUEST = 'GET_CERTIFICATION_REQUEST';
export const GET_CERTIFICATION_SUCCESS = 'GET_CERTIFICATION_SUCCESS';
export const GET_CERTIFICATION_FAILURE = 'GET_CERTIFICATION_FAILURE';

export const GET_ALL_CERTIFICATION_REQUEST = 'GET_ALL_CERTIFICATION_REQUEST';
export const GET_ALL_CERTIFICATION_SUCCESS = 'GET_ALL_CERTIFICATION_SUCCESS';
export const GET_ALL_CERTIFICATION_FAILURE = 'GET_ALL_CERTIFICATION_FAILURE';

export const UPDATE_CERTIFICATION_REQUEST = 'UPDATE_CERTIFICATION_REQUEST';
export const UPDATE_CERTIFICATION_SUCCESS = 'UPDATE_CERTIFICATION_SUCCESS';
export const UPDATE_CERTIFICATION_FAILURE = 'UPDATE_CERTIFICATION_FAILURE';

export const DELETE_CERTIFICATION_REQUEST = 'DELETE_CERTIFICATION_REQUEST';
export const DELETE_CERTIFICATION_SUCCESS = 'DELETE_CERTIFICATION_SUCCESS';
export const DELETE_CERTIFICATION_FAILURE = 'DELETE_CERTIFICATION_FAILURE';

export const GET_ALL_INHOUSEPROJECTS_REQUEST = 'GET_ALL_INHOUSEPROJECTS_REQUEST';
export const GET_ALL_INHOUSEPROJECTS_SUCCESS = 'GET_ALL_INHOUSEPROJECTS_SUCCESS';
export const GET_ALL_INHOUSEPROJECTS_FAILURE = 'GET_ALL_INHOUSEPROJECTS_FAILURE';

export const SAVE_INHOUSE_PROJECT_REQUEST = 'SAVE_INHOUSE_PROJECT_REQUEST';
export const SAVE_INHOUSE_PROJECT_SUCCESS = 'SAVE_INHOUSE_PROJECT_SUCCESS';
export const SAVE_INHOUSE_PROJECT_FAILURE = 'SAVE_INHOUSE_PROJECT_FAILURE';

export const GET_ALL_INHOUSE_PROJECTS_REQUEST = 'GET_ALL_INHOUSE_PROJECTS_REQUEST';
export const GET_ALL_INHOUSE_PROJECTS_SUCCESS = 'GET_ALL_INHOUSE_PROJECTS_SUCCESS';
export const GET_ALL_INHOUSE_PROJECTS_FAILURE = 'GET_ALL_INHOUSE_PROJECTS_FAILURE';

export const GET_INHOUSE_PROJECT_REQUEST = 'GET_INHOUSE_PROJECT_REQUEST';
export const GET_INHOUSE_PROJECT_SUCCESS = 'GET_INHOUSE_PROJECT_SUCCESS';
export const GET_INHOUSE_PROJECT_FAILURE = 'GET_INHOUSE_PROJECT_FAILURE';

export const UPDATE_INHOUSE_PROJECT_REQUEST = 'UPDATE_INHOUSE_PROJECT_REQUEST';
export const UPDATE_INHOUSE_PROJECT_SUCCESS = 'UPDATE_INHOUSE_PROJECT_SUCCESS';
export const UPDATE_INHOUSE_PROJECT_FAILURE = 'UPDATE_INHOUSE_PROJECT_FAILURE';

export const DELETE_INHOUSE_PROJECT_REQUEST = 'DELETE_INHOUSE_PROJECT_REQUEST';
export const DELETE_INHOUSE_PROJECT_SUCCESS = 'DELETE_INHOUSE_PROJECT_SUCCESS';
export const DELETE_INHOUSE_PROJECT_FAILURE = 'DELETE_INHOUSE_PROJECT_FAILURE';

export const SAVE_OTHER_PROJECT_REQUEST = 'SAVE_OTHER_PROJECT_REQUEST';
export const SAVE_OTHER_PROJECT_SUCCESS = 'SAVE_OTHER_PROJECT_SUCCESS';
export const SAVE_OTHER_PROJECT_FAILURE = 'SAVE_OTHER_PROJECT_FAILURE';

export const GET_ALL_OTHER_PROJECTS_REQUEST = 'GET_ALL_OTHER_PROJECTS_REQUEST';
export const GET_ALL_OTHER_PROJECTS_SUCCESS = 'GET_ALL_OTHER_PROJECTS_SUCCESS';
export const GET_ALL_OTHER_PROJECTS_FAILURE = 'GET_ALL_OTHER_PROJECTS_FAILURE';

export const GET_OTHER_PROJECT_REQUEST = 'GET_OTHER_PROJECT_REQUEST';
export const GET_OTHER_PROJECT_SUCCESS = 'GET_OTHER_PROJECT_SUCCESS';
export const GET_OTHER_PROJECT_FAILURE = 'GET_OTHER_PROJECT_FAILURE';

export const UPDATE_OTHER_PROJECT_REQUEST = 'UPDATE_OTHER_PROJECT_REQUEST';
export const UPDATE_OTHER_PROJECT_SUCCESS = 'UPDATE_OTHER_PROJECT_SUCCESS';
export const UPDATE_OTHER_PROJECT_FAILURE = 'UPDATE_OTHER_PROJECT_FAILURE';

export const DELETE_OTHER_PROJECT_REQUEST = 'DELETE_OTHER_PROJECT_REQUEST';
export const DELETE_OTHER_PROJECT_SUCCESS = 'DELETE_OTHER_PROJECT_SUCCESS';
export const DELETE_OTHER_PROJECT_FAILURE = 'DELETE_OTHER_PROJECT_FAILURE';

export const DELETE_RESUME_REQUEST = 'DELETE_RESUME_REQUEST';
export const DELETE_RESUME_SUCCESS = 'DELETE_RESUME_SUCCESS';
export const DELETE_RESUME_FAILURE = 'DELETE_RESUME_FAILURE';

export const GET_RESUME_REQUEST = 'GET_RESUME_REQUEST';
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
export const GET_RESUME_FAILURE = 'GET_RESUME_FAILURE';

export const GET_ALL_PROJECT_DOMAIN_REQUEST = 'GET_ALL_PROJECT_DOMAIN_REQUEST';
export const GET_ALL_PROJECT_DOMAIN_SUCCESS = 'GET_ALL_PROJECT_DOMAIN_SUCCESS';
export const GET_ALL_PROJECT_DOMAIN_FAILURE = 'GET_ALL_PROJECT_DOMAIN_FAILURE';

export const GET_ALL_TECHNOLOGY_REQUEST = 'GET_ALL_TECHNOLOGY_REQUEST';
export const GET_ALL_TECHNOLOGY_SUCCESS = 'GET_ALL_TECHNOLOGY_SUCCESS';
export const GET_ALL_TECHNOLOGY_FAILURE = 'GET_ALL_TECHNOLOGY_FAILURE';

export const GET_ALL_PROJECTS_FOR_SELECT_REQUEST = 'GET_ALL_PROJECTS_FOR_SELECT_REQUEST';
export const GET_ALL_PROJECTS_FOR_SELECT_SUCCESS = 'GET_ALL_PROJECTS_FOR_SELECT_SUCCESS';
export const GET_ALL_PROJECTS_FOR_SELECT_FAILURE = 'GET_ALL_PROJECTS_FOR_SELECT_FAILURE';

export const FETCH_RESUME_ID_REQUEST = 'FETCH_RESUME_ID_REQUEST';
export const FETCH_RESUME_ID_SUCCESS = 'FETCH_RESUME_ID_SUCCESS';
export const FETCH_RESUME_ID_FAILURE = 'FETCH_RESUME_ID_FAILURE';

import { setLoading } from './SpinnerActions';

export const getDesignations = () => {
    return (dispatch) => {
        dispatch({ type: GET_DESIGNATIONS_REQUEST });
        axios
            .get(`${hostname}/api/v1/designation/active`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_DESIGNATIONS_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_DESIGNATIONS_FAILURE, payload: error.message });
            });
    };
};

export const getAllProjectsForSelect = () => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_PROJECTS_FOR_SELECT_REQUEST });
        axios
            .get(`${hostname}/api/v1/InHouseProject/active`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_PROJECTS_FOR_SELECT_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_PROJECTS_FOR_SELECT_FAILURE, payload: error.message });
            });
    };
};

export const getAllProjectDomain = () => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_PROJECT_DOMAIN_REQUEST });

        axios
            .get(`${hostname}/api/v1/Domain/active`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_PROJECT_DOMAIN_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_PROJECT_DOMAIN_FAILURE, payload: error.message });
            });
    };
};

export const getAllTechnology = () => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_TECHNOLOGY_REQUEST });

        axios
            .get(`${hostname}/api/v1/technology/active`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_TECHNOLOGY_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_TECHNOLOGY_FAILURE, payload: error.message });
            });
    };
};

export const saveBasicInfo = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_BASIC_INFO_REQUEST });

        axios
            .post(`${hostname}/${basicInfo.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            //.post(`https://localhost:7212/api/v1/Resume/BasicInfo`, formData)
            .then((response) => {
                const details = JSON.parse(localStorage.getItem('details'));
                dispatch({ type: SAVE_BASIC_INFO_SUCCESS, payload: response.data });
                localStorage.setItem('resumeId', response.data.data.id);
                dispatch(getBasicInfo(localStorage.getItem('resumeId')));

                // dispatch(fetchResumeId(details.userId));
                // window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: SAVE_BASIC_INFO_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const saveAndNextCreate = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_BASIC_INFO_REQUEST });
        axios
            .post(`${hostname}/${basicInfo.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            //.post(`https://localhost:7212/api/v1/Resume/BasicInfo`, formData)
            .then((response) => {
                const details = JSON.parse(localStorage.getItem('details'));
                dispatch({ type: SAVE_BASIC_INFO_SUCCESS, payload: response.data });
                window.location.replace(`/workexperience/${response.data.data.id}`);
                localStorage.setItem('resumeId', response.data.data.id);
                dispatch(getBasicInfo(localStorage.getItem('resumeId')));

                // dispatch(fetchResumeId(details.userId));
            })
            .catch((error) => {
                dispatch({ type: SAVE_BASIC_INFO_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const saveAndCloseCreate = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_BASIC_INFO_REQUEST });

        axios
            .post(`${hostname}/${basicInfo.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            //.post(`https://localhost:7212/api/v1/Resume/BasicInfo`, formData)
            .then((response) => {
                const details = JSON.parse(localStorage.getItem('details'));
                dispatch({ type: SAVE_BASIC_INFO_SUCCESS, payload: response.data });
                localStorage.setItem('resumeId', response.data.data.id);
                dispatch(getBasicInfo(response.data.data.id));
                window.location.replace(`/dashboard`);

                // dispatch(fetchResumeId(details.userId));
            })
            .catch((error) => {
                dispatch({ type: SAVE_BASIC_INFO_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteResume = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_RESUME_REQUEST });
        axios
            .delete(
                `${hostname}/${resume.delete(id)}`,
                { id: id },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: DELETE_RESUME_SUCCESS, payload: response.data });
                dispatch({ type: 'RESET_BASIC_INFO_STATE' });
                localStorage.removeItem('basicInfo');
                localStorage.removeItem('resumeId');
                localStorage.removeItem('showEditSection');

                // window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: DELETE_RESUME_FAILURE, payload: error.message });
            });
    };
};

export const getResume = () => {
    return (dispatch) => {
        dispatch({ type: GET_RESUME_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${resume.get}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_RESUME_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_RESUME_FAILURE, payload: error.message });
            });
    };
};

export const fetchResumeId = (userId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_RESUME_ID_REQUEST });
        const apiUrl = `${hostname}/api/v1/Resume/resumestatus`;
        fetch(`${apiUrl}?userId=${userId}`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                const resumeId = data;
                dispatch({ type: FETCH_RESUME_ID_SUCCESS, payload: resumeId });
                localStorage.setItem('resumeId', resumeId);
            })
            .catch((error) => {
                dispatch({ type: FETCH_RESUME_ID_FAILURE, payload: error.message });
                console.error('Error fetching resumeId:', error);
            });
    };
};

// export const saveBasicInfo = (formData) => {
//     debugger;
//     return (dispatch) => {
//         dispatch({ type: SAVE_BASIC_INFO_REQUEST });
//         fetch(`${hostname}/${basicInfo.endpoint}`, {
//             method: 'POST',
//             body: formData
//         })
//             .then((response) => {
//                 if (!response.ok) {
//                     throw Error(response.statusText);
//                 }
//                 return response.json();
//             })
//             .then((data) => {
//                 dispatch({ type: SAVE_BASIC_INFO_SUCCESS, payload: data });
//             })
//             .catch((error) => {
//                 dispatch({ type: SAVE_BASIC_INFO_FAILURE, payload: error.message });
//             });
//     };
// };

export const getBasicInfo = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_BASIC_INFO_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${basicInfo.get(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_BASIC_INFO_SUCCESS, payload: response.data });
                localStorage.setItem('basicInfo', JSON.stringify(response.data));
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_BASIC_INFO_FAILURE, payload: error.message });
            });
    };
};

export const updateBasicInfo = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_BASIC_INFO_REQUEST });

        axios
            .put(
                `${hostname}/${basicInfo.update(id)}`,
                { id, ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: UPDATE_BASIC_INFO_SUCCESS, payload: response.data });
                dispatch(getBasicInfo(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_BASIC_INFO_FAILURE, payload: error.message });
            });
    };
};

export const saveAndNextUpdate = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_BASIC_INFO_REQUEST });

        axios
            .put(
                `${hostname}/${basicInfo.update(id)}`,
                { id, ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: UPDATE_BASIC_INFO_SUCCESS, payload: response.data });
                dispatch(getBasicInfo(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_BASIC_INFO_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const saveAndCloseUpdate = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_BASIC_INFO_REQUEST });

        axios
            .put(
                `${hostname}/${basicInfo.update(id)}`,
                { id, ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: UPDATE_BASIC_INFO_SUCCESS, payload: response.data });
                dispatch(getBasicInfo(localStorage.getItem('resumeId')));
                // window.location.replace(`/dashboard`);
            })
            .catch((error) => {
                dispatch({ type: UPDATE_BASIC_INFO_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const saveEducation = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_EDUCATION_REQUEST });

        axios
            .post(`${hostname}/${educationEP.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: SAVE_EDUCATION_SUCCESS, payload: response.data });
                dispatch(getAllEducation(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: SAVE_EDUCATION_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const getAllEducation = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_EDUCATION_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${educationEP.getAll(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_EDUCATION_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_EDUCATION_FAILURE, payload: error.message });
            });
    };
};

export const getEducation = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_EDUCATION_REQUEST });

        axios
            .get(`${hostname}/${educationEP.get(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_EDUCATION_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_EDUCATION_FAILURE, payload: error.message });
            });
    };
};

export const updateEducation = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_EDUCATION_REQUEST });

        axios
            .put(`${hostname}/${educationEP.update(id)}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: UPDATE_EDUCATION_SUCCESS, payload: response.data });
                dispatch(getAllEducation(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_EDUCATION_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteEducation = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_EDUCATION_REQUEST });

        axios
            .delete(`${hostname}/${educationEP.delete(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: DELETE_EDUCATION_SUCCESS, payload: response.data });
                dispatch(getAllEducation(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: DELETE_EDUCATION_FAILURE, payload: error.message });
            });
    };
};
//Skill
export const saveAbilities = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_ABILITIES_REQUEST });

        axios
            .post(`${hostname}/${abilitiesEP.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: SAVE_ABILITIES_SUCCESS, payload: response.data });
                dispatch(getAllAbilities(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: SAVE_ABILITIES_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const getAllAbilities = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_ABILITIES_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${abilitiesEP.getAll(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_ABILITIES_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_ABILITIES_FAILURE, payload: error.message });
            });
    };
};

export const getAbilities = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_ABILITIES_REQUEST });

        axios
            .get(`${hostname}/${abilitiesEP.get(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ABILITIES_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_ABILITIES_FAILURE, payload: error.message });
            });
    };
};

export const updateAbilities = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_ABILITIES_REQUEST });

        axios
            .put(`${hostname}/${abilitiesEP.update(id)}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: UPDATE_ABILITIES_SUCCESS, payload: response.data });
                dispatch(getAllAbilities(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_ABILITIES_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteAbilities = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_ABILITIES_REQUEST });

        axios
            .delete(`${hostname}/${abilitiesEP.delete(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: DELETE_ABILITIES_SUCCESS, payload: response.data });
                dispatch(getAllAbilities(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: DELETE_ABILITIES_FAILURE, payload: error.message });
            });
    };
};
export const saveCertification = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_CERTIFICATION_REQUEST });

        axios
            .post(`${hostname}/${certificationEP.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: SAVE_CERTIFICATION_SUCCESS, payload: response.data });
                dispatch(getAllCertification(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: SAVE_CERTIFICATION_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const getAllCertification = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_CERTIFICATION_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${certificationEP.getAll(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_CERTIFICATION_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_CERTIFICATION_FAILURE, payload: error.message });
            });
    };
};

export const getCertification = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_CERTIFICATION_REQUEST });

        axios
            .get(`${hostname}/${certificationEP.getAll(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_CERTIFICATION_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_CERTIFICATION_FAILURE, payload: error.message });
            });
    };
};

export const updateCertification = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_CERTIFICATION_REQUEST });

        axios
            .put(`${hostname}/${certificationEP.update(id)}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: UPDATE_CERTIFICATION_SUCCESS, payload: response.data });
                dispatch(getAllCertification(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_CERTIFICATION_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteCertification = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_CERTIFICATION_REQUEST });

        axios
            .delete(`${hostname}/${certificationEP.delete(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: DELETE_CERTIFICATION_SUCCESS, payload: response.data });
                dispatch(getAllCertification(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: DELETE_CERTIFICATION_FAILURE, payload: error.message });
            });
    };
};

export const saveInHouseProject = (formData) => {
    // debugger;
    return (dispatch) => {
        dispatch({ type: SAVE_INHOUSE_PROJECT_REQUEST });

        axios
            .post(`${hostname}/${inHouseProjects.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: SAVE_INHOUSE_PROJECT_SUCCESS, payload: response.data });
                dispatch(getAllInHouseProjects(localStorage.getItem('resumeId')));
                localStorage.setItem('isErrors', false);
            })
            .catch((error) => {
                localStorage.setItem('isErrors', true);
                dispatch({ type: SAVE_INHOUSE_PROJECT_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const getAllInHouseProjects = (resumeId) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_INHOUSE_PROJECTS_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${inHouseProjectsEP.getAll(resumeId)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_INHOUSE_PROJECTS_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_INHOUSE_PROJECTS_FAILURE, payload: error.message });
            });
    };
};

export const getInHouseProject = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_INHOUSE_PROJECT_REQUEST });

        axios
            .get(`${hostname}/${inHouseProjectsEP.get(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_INHOUSE_PROJECT_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_INHOUSE_PROJECT_FAILURE, payload: error.message });
            });
    };
};

export const updateInHouseProject = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_INHOUSE_PROJECT_REQUEST });

        axios
            .put(`${hostname}/${inHouseProjectsEP.update(id)}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: UPDATE_INHOUSE_PROJECT_SUCCESS, payload: response.data });
                dispatch(getAllInHouseProjects(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_INHOUSE_PROJECT_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteInHouseProject = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_INHOUSE_PROJECT_REQUEST });

        axios
            .delete(`${hostname}/${inHouseProjectsEP.delete(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: DELETE_INHOUSE_PROJECT_SUCCESS, payload: id });
                window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: DELETE_INHOUSE_PROJECT_FAILURE, payload: error.message });
            });
    };
};

export const saveOtherProject = (formData) => {
    return (dispatch) => {
        dispatch({ type: SAVE_OTHER_PROJECT_REQUEST });

        axios
            .post(`${hostname}/${otherProjectsEP.endpoint}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: SAVE_OTHER_PROJECT_SUCCESS, payload: response.data });
                dispatch(getAllOtherProjects(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: SAVE_OTHER_PROJECT_FAILURE, payload: error.response.data.errors });
                localStorage.setItem('isError', 'true');
            });
    };
};

export const getAllOtherProjects = (resumeId) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_OTHER_PROJECTS_REQUEST });
        dispatch(setLoading(true));
        axios
            .get(`${hostname}/${otherProjectsEP.getAll(resumeId)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_ALL_OTHER_PROJECTS_SUCCESS, payload: response.data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_OTHER_PROJECTS_FAILURE, payload: error.message });
            });
    };
};

export const getOtherProject = (id) => {
    return (dispatch) => {
        dispatch({ type: GET_OTHER_PROJECT_REQUEST });

        axios
            .get(`${hostname}/${otherProjectsEP.get(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: GET_OTHER_PROJECT_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: GET_OTHER_PROJECT_FAILURE, payload: error.message });
            });
    };
};

export const updateOtherProject = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_OTHER_PROJECT_REQUEST });

        axios
            .put(`${hostname}/${otherProjectsEP.update(id)}`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: UPDATE_OTHER_PROJECT_SUCCESS, payload: response.data });
                dispatch(getAllOtherProjects(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_OTHER_PROJECT_FAILURE, payload: error.response.data.errors });
            });
    };
};

export const deleteOtherProject = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_OTHER_PROJECT_REQUEST });
        axios
            .delete(`${hostname}/${otherProjectsEP.delete(id)}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                dispatch({ type: DELETE_OTHER_PROJECT_SUCCESS, payload: id });
                dispatch(getAllOtherProjects(localStorage.getItem('resumeId')));
            })
            .catch((error) => {
                dispatch({ type: DELETE_OTHER_PROJECT_FAILURE, payload: error.message });
            });
    };
};

export const RESET_BASIC_INFO_STATE = () => {
    return {
        type: 'RESET_BASIC_INFO_STATE'
    };
};

// export const getResumeId = (userId) => {
//     return (dispatch) => {
//         dispatch({ type: DELETE_OTHER_PROJECT_REQUEST });
//         fetch(`https://localhost:7212/api/v1/Resume/resumestatus?userId=${userId}`)
//             .then((response) => response.json())
//             .then((data) => {
//                 const resumeId = data;

//                 localStorage.setItem('resumeId', resumeId);
//                 dispatch(getBasicInfo(data));
//             })
//             .catch((error) => {
//                 console.error('Error fetching resumeId:', error);
//             });
//     };
// };
