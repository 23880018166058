const initialState = {
    open: false,
    message: '',
    severity: ''
};

const SnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_SNACKBAR':
            return {
                ...state,
                open: true,
                message: action.payload.message,
                severity: action.payload.severity
            };
        case 'HIDE_SNACKBAR':
            return {
                ...state,
                open: false,
                message: '',
                severity: undefined
            };
        default:
            return state;
    }
};

export default SnackbarReducer;
