import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

const NotFound = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '50vh',
                maxWidth: '60%',
                margin: '10% auto',
                borderRadius: '10px',
                bgcolor: 'white',
                padding: '2rem'
            }}
        >
            <ErrorIcon sx={{ color: '#FF0000', fontSize: '4rem' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 3 }}>
                Oops! Page not found.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                The page you are looking for might have been removed or is temporarily unavailable.
            </Typography>
        </Box>
    );
};

export default NotFound;
