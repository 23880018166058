const TechnologyReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_TECHNOLOGY_SUCCESS':
            return {
                technology: action.payload,
                message: 'Success'
            };
        case 'TECHNOLOGY_ADD_SUCCESS':
            return {
                addMessage: action.payload,
                message: 'Success'
            };
        case 'TECHNOLOGY_ADD_FAILED':
            return {
                addMessage: action.payload,
                message: 'Failed'
            };
        case 'UPDATE_TECHNOLOGY_SUCCESS':
            return {
                editMessage: action.payload,
                message: 'Success'
            };
        case 'UPDATE_TECHNOLOGY_FAILED':
            return {
                editMessage: action.payload,
                message: 'Failed'
            };
        case 'DELETE_TECHNOLOGY_SUCCESS':
            return {
                deleteMessage: action.payload,
                message: 'Success'
            };
        case 'DELETE_TECHNOLOGY_FAILED':
            return {
                deleteMessage: action.payload,
                message: 'Failed'
            };
        default:
            return state;
    }
};

export default TechnologyReducer;
