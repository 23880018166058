import { useRoutes } from 'react-router-dom';
import axiosConn from 'views/pages/authentication/auth-forms/AxiosConn';
import { React } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { store } from '../store/index';
import apiEndPoints from '../../src/constants/api';
const { hostname } = apiEndPoints;
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Login from 'views/pages/authentication/authentication3/Login3';
import FirebaseLogin from 'views/pages/authentication/auth-forms/AuthLogin';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import Designation from 'views/pages/admin/Designation/Designation';
import { createRoutes } from './MainRoutes';
import MainLayout from 'layout/MainLayout';
import NotFound from 'views/errorResponse/NotFound';
import Forbidden from 'views/errorResponse/Forbidden';
import { redirect } from 'react-router-dom';
import Resume from 'views/pages/admin/Resume/Resume';
import AdminDashboard from 'views/pages/admin/DashBoard';
import Dummy from 'views/pages/admin/Dummy';
import Loadable from 'ui-component/Loadable';
import Dashboard from 'views/dashboard/Default';
import Profile from 'views/pages/User/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { Fetch_PERMISSIONS, GET_RESUME_ID } from 'action/PermissionAction';
import { fetchResumeId, getBasicInfo } from 'action/ResumeAction';
import { FETCH_TOKEN_SUCCESS } from 'action/TokenAction';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import axios from 'axios';
import GeneralSetting from 'views/pages/admin/Settings/GeneralSetting';
import Template from 'views/pages/admin/UploadTemplate/Template';
import UpdateTemplate from 'views/pages/admin/UploadTemplate/UpdateTemplate';
// ==============================|| ROUTING RENDER ||============================== //
//const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));

export default function ThemeRoutes() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [createdRoutes, setRoutes] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [createdRoute, setCreatedRoute] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [message, setMessage] = useState();
    const axiosConn = useAxiosInterceptors();
    const token = useSelector((state) => state.tokenReducer.token);

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                setIsLoggedIn(window.localStorage.getItem('isLoggedIn'));
                setIsAdmin(window.localStorage.getItem('isAdmin'));
                const createdRoute = await createRoutes(token.claims);
                setRoutes(createdRoute);
            }
        };
        fetchData();
    }, []);

    const getResumeId = (userId) => {
        return new Promise((resolve, reject) => {
            fetch(`${hostname}/api/v1/Resume/resumestatus?userId=${userId}`)
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem('resumeId', data);
                    resolve(data);
                })
                .catch((error) => {
                    console.error('Error fetching resumeId:', error);
                    reject(error);
                });
        });
    };

    const login = async (data) => {
        try {
            let payload = {
                email: data.email.trim(),
                password: data.password
            };
            //  const apiUrl= isGoogleLogin ? "https://localhost:7212/api/v1/User/google" : "https://localhost:7212/api/v1/Token/login";

            const res = await axios.post(`${hostname}/api/v1/Token/login`, payload);
            if (res.data.data.accessToken) {
                dispatch(FETCH_TOKEN_SUCCESS(res.data.data));
                window.localStorage.setItem('token', res.data.data.accessToken);
                console.log('claims', res.data.data.claims);
                const createdRoute = await createRoutes(res.data.data.claims);
                const { userId, firstName, lastName, email } = res.data.data;
                const details = { userId, firstName, lastName, email };
                window.localStorage.setItem('details', JSON.stringify(details));
                window.localStorage.setItem('userId', userId);
                setCreatedRoute(createdRoute);
                window.localStorage.setItem('isLoggedIn', true);
                window.localStorage.setItem('isAdmin', res.data.data.isAdmin);
                const permission = res.data.data.claims.map((obj) => obj.value);
                window.localStorage.setItem('Permissions', JSON.stringify(permission));
                store.dispatch(Fetch_PERMISSIONS(permission));
                // dispatch(GET_RESUME_ID(details.userId));
                setMessage('');
                getResumeId(details.userId).then((resumeId) => {
                    window.localStorage.setItem('resumeId', resumeId);
                    dispatch(getBasicInfo(resumeId));
                    setIsAdmin(window.localStorage.getItem('isAdmin'));
                    setIsLoggedIn(window.localStorage.getItem('isLoggedIn'));
                    navigate('/dashboard');
                });
                setRoutes(createdRoute);
            } else {
                console.log('Login', res);
            }
        } catch (error) {
            if (error.response.data.statusCode == 400) {
                setMessage(error.response.data.errors[0].message);
            } else {
                if (error.response.data.statusCode == 401) setMessage(error.response.data.errorMessage);
            }
            // console.log('in catch', error);
        }
    };

    return (
        <Routes>
            <Route path="/" element={<FirebaseLogin login={login} message={message} />} />
            {isLoggedIn && (
                <Route element={<MainLayout />}>
                    {isAdmin === 'true' ? (
                        <>
                            <Route path="/dashboard" element={<AdminDashboard />} />
                        </>
                    ) : (
                        <>
                            <Route path="/dashboard" element={<Dashboard />} />
                        </>
                    )}
                    {createdRoutes &&
                        createdRoutes.map((data) => {
                            return <Route key={data.path} path={data.path} element={<data.component />} />;
                        })}
                    <Route path="/profile/:id" element={<Profile />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/resumes" element={<Resume />} />
                </Route>
            )}
        </Routes>
    );
}
