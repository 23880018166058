// project imports
import { useEffect, useState } from 'react';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import usePages from '../../../../menu-items/pages';
import { useSelector } from 'react-redux';
import userRoles from 'menu-items/UserRole';
import resumePages from 'menu-items/resumeSections';
import dashboardMenu from 'menu-items/dashboard';
import UploadFile from 'menu-items/UploadFile';
//import pages from '/src/layout/pages';
//import { resumeCreateSections, resumeEditSections } from './resumeSections';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const pages = usePages();
    const role = userRoles();
    const resume = resumePages();
    const file = UploadFile();
    const dashboard = dashboardMenu();
    const menuItem = [dashboard, resume, pages, role, file];

    const navItems = menuItem.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return null;
        }
    });
    return <>{navItems}</>;
};

export default MenuList;
