// // import { createStore } from 'redux';
// import reducer from './reducer';
// import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

// const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

// const initialState = {
//     userLogin: { userInfo: userInfoFromStorage }
// };

// const middleware = [thunk];
// // ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
// const persister = 'Free';

// export default { store, persister };

import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['showMenuPermissions', 'fetchPermissions', 'tokenReducer']
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store);
