import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import TitleCard from 'ui-component/cards/Skeleton/Skeleton';
import SkeletonTechnologyCard from 'ui-component/cards/Skeleton/TechnologyCard';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import apiEndPoints from '../../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
const { hostname } = apiEndPoints;

const CardWrapper = styled(TitleCard)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 110,
        height: 110,
        background: theme.palette.error.dark,
        borderRadius: '50%',
        top: -5,
        right: -5,
        [theme.breakpoints.down('sm')]: {
            top: -15,
            right: -10
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 110,
        height: 110,
        background: theme.palette.error.dark,
        borderRadius: '50%',
        top: -15,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -15,
            right: -10
        }
    }
}));

const TechnologyCard = ({ isLoading }) => {
    const theme = useTheme();
    const [technologycount, setTechnologyCount] = useState([]);
    const axiosConn = useAxiosInterceptors();

    useEffect(() => {
        if (!isLoading) {
            axiosConn
                .get(`${hostname}/api/v1/Dashboard/TechnologyCount`)
                .then((response) => {
                    setTechnologyCount(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [isLoading]);
    return (
        <>
            {isLoading ? (
                <SkeletonTechnologyCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <DevicesOutlinedIcon sx={{ color: 'theme.palette.common.white' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                        {technologycount.length > 0 ? technologycount : 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 1.25 }}>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 700,
                                        color: theme.palette.common.white
                                    }}
                                >
                                    Technologies
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TechnologyCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TechnologyCard;
