export const FETCH_TOKEN_SUCCESS = (data) => {
    return {
        type: 'FETCH_TOKEN_SUCCESS',
        payload: data
    };
};

export const RESET_TOKEN = () => {
    return {
        type: 'RESET_TOKEN'
    };
};
