import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import { useSelector } from 'react-redux';
import { style } from '@mui/system';
import { School } from '@mui/icons-material';

const usePages = () => {
    // constant
    const icons = {
        PortraitOutlinedIcon,
        HubOutlinedIcon,
        WysiwygOutlinedIcon,
        LaptopChromebookOutlinedIcon,
        SettingsOutlinedIcon,
        CategoryOutlinedIcon,
        DevicesOutlinedIcon,
        School
    };

    // get permissions from redux state
    const permissions = useSelector((state) => state.fetchPermissions.permissions);

    const pages = {
        id: 'pages',
        type: 'group',
        title: null,
        children: []
    };

    if (permissions.includes('setting.general')) {
        pages.children.push({
            id: 'setting',
            title: 'General',
            type: 'item',
            icon: icons.SettingsOutlinedIcon,
            url: '/settings'
        });
    }
    if (permissions.includes('designation.all.filter.view')) {
        pages.children.push({
            id: 'designation',
            title: 'Designations',
            type: 'item',
            icon: icons.PortraitOutlinedIcon,
            url: '/designations'
        });
    }

    if (permissions.includes('domain.all.filter.view')) {
        pages.children.push({
            id: 'domain',
            title: 'Domains',
            type: 'item',
            icon: icons.HubOutlinedIcon,
            url: '/domains'
        });
    }

    if (permissions.includes('inhouse.project.all.filter.view')) {
        pages.children.push({
            id: 'projects',
            title: 'Projects',
            type: 'item',
            icon: icons.WysiwygOutlinedIcon,
            url: '/inhouseprojects'
        });
    }
    if (permissions.includes('technology.all.filter.view')) {
        pages.children.push({
            id: 'technologies',
            title: 'Techhub',
            type: 'collapse',
            icon: icons.LaptopChromebookOutlinedIcon,
            //url: '/technologies'
            children: [
                {
                    id: '1',
                    title: 'Categories',
                    type: 'item',
                    icon: icons.CategoryOutlinedIcon,
                    url: '/technologyCategories'
                },
                {
                    id: '2',
                    title: 'Technologies',
                    type: 'item',
                    icon: icons.DevicesOutlinedIcon,
                    url: '/technologies'
                }
            ]
        });
    }
    if (permissions.includes('qualification.all.filter.view')) {
        pages.children.push({
            id: 'qualification',
            title: 'Qualifications',
            type: 'item',
            icon: icons.School,
            url: '/qualifications'
        });
    }

    if (pages.children.length > 0) {
        pages.title = 'Settings';
    }

    return pages;
};

export default usePages;
