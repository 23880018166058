import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const DomainCard = () => (
  <Card>
    <CardContent>
      <Grid container direction="column">
        <Grid item>
          <Grid containe>
            <Grid item>
              <Skeleton variant="rectangular" />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular"  />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular"  />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default DomainCard;
