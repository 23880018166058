import { FETCH_TOKEN_SUCCESS } from 'action/TokenAction';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import apiEndPoints from '../../../../constants/api';
import { RESET_BASIC_INFO_STATE } from 'action/ResumeAction';
const { hostname } = apiEndPoints;

const useAxiosInterceptors = () => {
    const axiosConn = axios.create({
        baseURL: `${hostname}`
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.tokenReducer.token);

    useEffect(() => {
        // Request interceptor (Outgoing)
        const requestInterceptor = axiosConn.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                console.log('Interceptor Request (Outgoing) ', config);

                if (token.accessToken) {
                    //   let tokens = JSON.parse(localStorage.getItem('tokens'));
                    //   console.log('token', tokens);
                    config.headers.Authorization = `Bearer ${token.accessToken}`;
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        // Response interceptor (Incoming)
        const responseInterceptor = axiosConn.interceptors.response.use(
            function (response) {
                console.log('Interceptor Response (Incoming) ', response);

                return response;
            },
            function (error) {
                //console.log("Erorrrrrr",error)
                if (error.response.status === 401) {
                    updateToken();
                }
                return Promise.reject(error);
            }
        );

        const updateToken = async () => {
            try {
                //let tokens = JSON.parse(localStorage.getItem('tokens'));
                let payload = {
                    userId: token.userId,
                    refreshToken: token.refreshToken
                };
                const res = await axiosConn.post(`${hostname}/api/v1/Token/refresh_token`, payload);
                if (res) {
                    dispatch(FETCH_TOKEN_SUCCESS(res.data));
                    localStorage.setItem('token', res.data.accessToken);
                    window.location.reload();
                }
            } catch (error) {
                if (error.response.status === 400) {
                    window.localStorage.removeItem('isLoggedIn');
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('isAdmin');
                    // dispatch('RESET_BASIC_INFO_STATE');
                    dispatch(RESET_BASIC_INFO_STATE());
                    navigate('/');
                }
            }
        };

        // Clean up the interceptors when unmounting
        return () => {
            axiosConn.interceptors.request.eject(requestInterceptor);
            axiosConn.interceptors.response.eject(responseInterceptor);
        };
    });

    return axiosConn;
};

export default useAxiosInterceptors;
