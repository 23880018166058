import axios from 'axios';

export const Fetch_PERMISSIONS = (data) => {
    return {
        type: 'GENERATE_MENU',
        payload: data
    };
};

export const SHOW_MENU = (data) => {
    return {
        type: 'SHOW_MENU',
        payload: data
    };
};

export const GET_RESUME_ID = (userId) => {
    return (dispatch) => {
        axios
            .get(`https://localhost:7212/api/v1/Resume/resumestatus?userId=${userId}`)
            .then((response) => {
                dispatch({
                    type: 'FETCH_RESUME_ID',
                    payload: response.data
                });
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };
};
