import { Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import apiEndPoints from '../../../../../src/constants/api';
import { useNavigate } from 'react-router';
const { hostname } = apiEndPoints;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const GeneralSetting = () => {
    const axiosConn = useAxiosInterceptors();
    const [checked, setChecked] = useState();
    const [setting, setSetting] = useState([]);
    const [payload, setPayload] = useState([]);
    const [maskname, setMaskName] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Setting`)
            .then((response) => {
                // setSetting(response.data);
                const maskNameSetting = response.data.find((item) => item.name === 'mask_name');
                if (maskNameSetting) {
                    console.log('saddadad', maskNameSetting.value);
                    console.log('typeof maskNameSetting.value:', typeof maskNameSetting.value);
                    const boolValue = maskNameSetting.value === 'true';
                    console.log('Bool', boolValue);
                    setMaskName(boolValue);
                    setChecked(boolValue);
                }
                setPayload(response.data);
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }, []);

    const handleChange = (event) => {
        console.log('checked', checked);
        setChecked(event.target.checked);
        setMaskName(event.target.checked.toString());
    };

    const updateSetting = () => {
        const updatedPayload = [...payload];
        const settingToUpdate = updatedPayload.find((item) => item.name === 'mask_name');

        if (settingToUpdate) {
            settingToUpdate.value = maskname;
        }
        console.log('Updated payload', updatedPayload);
        axiosConn
            .put(`${hostname}/api/v1/Setting`, updatedPayload)
            .then((response) => {
                navigate('/resumes');
                console.log('Setting updated successfully:', response.data);
            })
            .catch((error) => {
                console.log('Error updating setting:', error);
            });
    };

    console.log('Maskname', maskname);
    console.log('checked', checked);

    return (
        <Box mt={15} ml={35}>
            <Paper zIndex="1" direction="row" sx={{ maxWidth: '70%', mt: '5%' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <SettingsOutlinedIcon sx={{ marginRight: '5px' }} fontSize="medium" />
                                        <span>General</span>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Mask name while downloading resume
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Switch color="secondary" checked={checked} onChange={handleChange} />
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                    <Divider />
                </TableContainer>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2%', ml: '23%' }}>
                <Button variant="contained" color="secondary" sx={{ color: 'white' }} onClick={updateSetting}>
                    Save
                </Button>
                &nbsp;&nbsp;
                <Button variant="outlined" color="secondary" onClick={(e) => navigate('/dashboard')}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

export default GeneralSetting;
