import { IconKey } from '@tabler/icons';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { useSelector } from 'react-redux';

// constant

const resumePages = () => {
    const permissions = useSelector((state) => state.fetchPermissions.permissions);
    const show = useSelector((state) => state.showMenuPermissions.show);
    // constant
    const icons = {
        IconKey,
        PersonIcon,
        WorkIcon,
        SchoolIcon,
        PsychologyIcon
    };

    const resumeEditSections = {
        id: 'resumeEditSections',
        title: null,
        type: 'group',
        children: []
    };

    if (
        show == true &&
        localStorage.getItem('resumeId') != null &&
        localStorage.getItem('resumeId') != undefined &&
        localStorage.getItem('resumeId') != '' &&
        localStorage.getItem('resumeId') != 'null' &&
        localStorage.getItem('resumeId') != 'undefined' &&
        localStorage.getItem('resumeId') != '0'
    ) {
        if (permissions && permissions.includes('basic.info.edit')) {
            resumeEditSections.children.push({
                id: 'basicinfo',
                title: 'Basic Info',
                type: 'item',
                icon: icons.PersonIcon,
                url: localStorage.getItem('resumeId') ? `basicinfo/${localStorage.getItem('resumeId')}` : 'basicinfo'
            });
        }
        if (permissions && permissions.includes('abilities.all.view')) {
            resumeEditSections.children.push({
                id: 'abilities',
                title: 'Skill',
                type: 'item',
                icon: icons.PsychologyIcon,
                url: localStorage.getItem('resumeId') ? `abilities/${localStorage.getItem('resumeId')}` : 'abilities'
            });
        }
        if (permissions && permissions.includes('inhouse.experience.all.view')) {
            resumeEditSections.children.push({
                id: 'workexperience',
                title: 'Work Experience',
                type: 'item',
                icon: icons.WorkIcon,
                url: localStorage.getItem('resumeId') ? `workexperience/${localStorage.getItem('resumeId')}` : 'workexperience'
            });
        }
        if (permissions && permissions.includes('education.all.view')) {
            resumeEditSections.children.push({
                id: 'education',
                title: 'Education',
                type: 'item',
                icon: icons.SchoolIcon,
                url: localStorage.getItem('resumeId') ? `education/${localStorage.getItem('resumeId')}` : 'education'
            });
        }
        if (resumeEditSections.children.length > 0) {
            resumeEditSections.title = 'Edit Resume';
        }
    }
    return resumeEditSections;
};

export default resumePages;
