import {
    GET_DESIGNATIONS_REQUEST,
    GET_DESIGNATIONS_SUCCESS,
    GET_DESIGNATIONS_FAILURE,
    SAVE_BASIC_INFO_REQUEST,
    SAVE_BASIC_INFO_SUCCESS,
    SAVE_BASIC_INFO_FAILURE,
    GET_BASIC_INFO_REQUEST,
    GET_BASIC_INFO_SUCCESS,
    GET_BASIC_INFO_FAILURE,
    UPDATE_BASIC_INFO_REQUEST,
    UPDATE_BASIC_INFO_SUCCESS,
    UPDATE_BASIC_INFO_FAILURE,
    GET_EDUCATION_REQUEST,
    GET_EDUCATION_SUCCESS,
    GET_EDUCATION_FAILURE,
    GET_ALL_EDUCATION_REQUEST,
    GET_ALL_EDUCATION_SUCCESS,
    GET_ALL_EDUCATION_FAILURE,
    SAVE_EDUCATION_REQUEST,
    SAVE_EDUCATION_SUCCESS,
    UPDATE_EDUCATION_REQUEST,
    UPDATE_EDUCATION_SUCCESS,
    UPDATE_EDUCATION_FAILURE,
    SAVE_EDUCATION_FAILURE,
    //Skill
    GET_ABILITIES_REQUEST,
    GET_ABILITIES_SUCCESS,
    GET_ABILITIES_FAILURE,
    GET_ALL_ABILITIES_REQUEST,
    GET_ALL_ABILITIES_SUCCESS,
    GET_ALL_ABILITIES_FAILURE,
    SAVE_ABILITIES_REQUEST,
    SAVE_ABILITIES_SUCCESS,
    UPDATE_ABILITIES_REQUEST,
    UPDATE_ABILITIES_SUCCESS,
    UPDATE_ABILITIES_FAILURE,
    SAVE_ABILITIES_FAILURE,

    GET_ALL_INHOUSE_PROJECTS_REQUEST,
    GET_ALL_INHOUSE_PROJECTS_SUCCESS,
    GET_ALL_INHOUSE_PROJECTS_FAILURE,
    SAVE_INHOUSE_PROJECT_REQUEST,
    SAVE_INHOUSE_PROJECT_SUCCESS,
    SAVE_INHOUSE_PROJECT_FAILURE,
    GET_INHOUSE_PROJECT_REQUEST,
    GET_INHOUSE_PROJECT_SUCCESS,
    GET_INHOUSE_PROJECT_FAILURE,
    UPDATE_INHOUSE_PROJECT_REQUEST,
    UPDATE_INHOUSE_PROJECT_SUCCESS,
    UPDATE_INHOUSE_PROJECT_FAILURE,
    DELETE_INHOUSE_PROJECT_REQUEST,
    DELETE_INHOUSE_PROJECT_SUCCESS,
    DELETE_INHOUSE_PROJECT_FAILURE,
    SAVE_OTHER_PROJECT_REQUEST,
    SAVE_OTHER_PROJECT_SUCCESS,
    SAVE_OTHER_PROJECT_FAILURE,
    GET_ALL_OTHER_PROJECTS_REQUEST,
    GET_ALL_OTHER_PROJECTS_SUCCESS,
    GET_ALL_OTHER_PROJECTS_FAILURE,
    GET_OTHER_PROJECT_REQUEST,
    GET_OTHER_PROJECT_SUCCESS,
    GET_OTHER_PROJECT_FAILURE,
    UPDATE_OTHER_PROJECT_REQUEST,
    UPDATE_OTHER_PROJECT_SUCCESS,
    UPDATE_OTHER_PROJECT_FAILURE,
    DELETE_OTHER_PROJECT_REQUEST,
    DELETE_OTHER_PROJECT_SUCCESS,
    DELETE_OTHER_PROJECT_FAILURE,
    DELETE_RESUME_REQUEST,
    DELETE_RESUME_SUCCESS,
    DELETE_RESUME_FAILURE,
    GET_RESUME_REQUEST,
    GET_RESUME_SUCCESS,
    GET_RESUME_FAILURE,
    GET_ALL_PROJECT_DOMAIN_REQUEST,
    GET_ALL_PROJECT_DOMAIN_SUCCESS,
    GET_ALL_PROJECT_DOMAIN_FAILURE,
    GET_ALL_TECHNOLOGY_REQUEST,
    GET_ALL_TECHNOLOGY_SUCCESS,
    GET_ALL_TECHNOLOGY_FAILURE,
    GET_ALL_PROJECTS_FOR_SELECT_REQUEST,
    GET_ALL_PROJECTS_FOR_SELECT_SUCCESS,
    GET_ALL_PROJECTS_FOR_SELECT_FAILURE,
    DELETE_EDUCATION_REQUEST,
    DELETE_EDUCATION_SUCCESS,
    DELETE_EDUCATION_FAILURE,
    //skill
    DELETE_ABILITIES_REQUEST,
    DELETE_ABILITIES_SUCCESS,
    DELETE_ABILITIES_FAILURE,
    FETCH_RESUME_ID_SUCCESS,
    FETCH_RESUME_ID_FAILURE,
    FETCH_RESUME_ID_REQUEST,
    SAVE_CERTIFICATION_REQUEST,
    SAVE_CERTIFICATION_SUCCESS,
    SAVE_CERTIFICATION_FAILURE,
    GET_CERTIFICATION_REQUEST,
    GET_CERTIFICATION_SUCCESS,
    GET_CERTIFICATION_FAILURE,
    GET_ALL_CERTIFICATION_REQUEST,
    GET_ALL_CERTIFICATION_SUCCESS,
    GET_ALL_CERTIFICATION_FAILURE,
    UPDATE_CERTIFICATION_REQUEST,
    UPDATE_CERTIFICATION_SUCCESS,
    UPDATE_CERTIFICATION_FAILURE,
    DELETE_CERTIFICATION_REQUEST,
    DELETE_CERTIFICATION_SUCCESS,
    DELETE_CERTIFICATION_FAILURE
} from '../action/ResumeAction';

const initialDesignationState = {
    designations: [],
    loading: false,
    error: null
};

const initialBasicInfoState = {
    basicInfo: {},
    loading: false,
    error: null
};

const initialEducationState = {
    educationList: [],
    education: {},
    loading: false,
    error: null
};
const initialAbilitiesState = {
    abilitiesList: [],
    abilities: {},
    loading: false,
    error: null
};
const initialCertificationState = {
    certification: [],
    loading: false,
    error: null
};

const initialInHouseProjectState = {
    inHouseProjects: [],
    inHouseProject: {},
    loading: false,
    error: null,
    success: false
};

const initialOtherProjectState = {
    otherProjects: [],
    otherProject: null,
    loading: false,
    error: null
};

const initialResumeState = {
    loading: false,
    error: null,
    resumeData: {}
};

const initialProjectDomainState = {
    loading: false,
    projects: [],
    error: null
};

const initialTechnologiesState = {
    loading: false,
    technology: [],
    error: null
};

const initialAllInhouseProjectState = {
    loading: false,
    inHouseProjectsForSelect: [],
    error: null
};

const initialFetchResumeIdState = {
    resumeId: null,
    error: null
};

export const inHouseProjectsForSelectReducer = (state = initialAllInhouseProjectState, action) => {
    switch (action.type) {
        case GET_ALL_PROJECTS_FOR_SELECT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PROJECTS_FOR_SELECT_SUCCESS:
            return {
                loading: false,
                inHouseProjectsForSelect: action.payload,
                error: ''
            };
        case GET_ALL_PROJECTS_FOR_SELECT_FAILURE:
            return {
                loading: false,
                inHouseProjectsForSelect: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export const fetchResumeIdReducer = (state = initialFetchResumeIdState, action) => {
    switch (action.type) {
        case FETCH_RESUME_ID_REQUEST:
            return {
                ...state,
                loading: true
            };
        case 'FETCH_RESUME_ID_SUCCESS':
            return {
                ...state,
                resumeId: action.payload,
                loading: false,
                error: null
            };
        case 'FETCH_RESUME_ID_FAILURE':
            return {
                ...state,
                resumeId: null,
                loading: false,

                error: action.payload
            };
        default:
            return state;
    }
};

export const allTechnologyReducer = (state = initialTechnologiesState, action) => {
    switch (action.type) {
        case GET_ALL_TECHNOLOGY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_TECHNOLOGY_SUCCESS:
            return {
                loading: false,
                technology: action.payload,
                error: ''
            };
        case GET_ALL_TECHNOLOGY_FAILURE:
            return {
                loading: false,
                technology: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export const allProjectDomainReducer = (state = initialProjectDomainState, action) => {
    switch (action.type) {
        case GET_ALL_PROJECT_DOMAIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PROJECT_DOMAIN_SUCCESS:
            return {
                loading: false,
                projects: action.payload,
                error: ''
            };
        case GET_ALL_PROJECT_DOMAIN_FAILURE:
            return {
                loading: false,
                projects: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export const designationReducer = (state = initialDesignationState, action) => {
    switch (action.type) {
        case GET_DESIGNATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_DESIGNATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                designations: action.payload
            };
        case GET_DESIGNATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export const basicInfoReducer = (state = initialBasicInfoState, action) => {
    switch (action.type) {
        case SAVE_BASIC_INFO_REQUEST:
        case GET_BASIC_INFO_REQUEST:
        case UPDATE_BASIC_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SAVE_BASIC_INFO_SUCCESS:
        case GET_BASIC_INFO_SUCCESS:
        case UPDATE_BASIC_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                basicInfo: action.payload
            };
        case SAVE_BASIC_INFO_FAILURE:
        case GET_BASIC_INFO_FAILURE:
        case UPDATE_BASIC_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'RESET_BASIC_INFO_STATE':
            return initialBasicInfoState;
        default:
            return state;
    }
};

export const educationReducer = (state = initialEducationState, action) => {
    switch (action.type) {
        case SAVE_EDUCATION_REQUEST:
        case GET_ALL_EDUCATION_REQUEST:
        case GET_EDUCATION_REQUEST:
        case UPDATE_EDUCATION_REQUEST:
        case DELETE_EDUCATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case SAVE_EDUCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                educationList: [...state.educationList, action.payload],
                error: null
            };
        case GET_ALL_EDUCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                educationList: action.payload,
                error: null
            };
        case GET_EDUCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                education: action.payload,
                error: null
            };
        case UPDATE_EDUCATION_SUCCESS:
        case DELETE_EDUCATION_SUCCESS:
            return {
                ...state,
                educationList: state.educationList.filter((education) => education.id !== action.payload),
                isLoading: false,
                error: null
            };

        case SAVE_EDUCATION_FAILURE:
        case GET_ALL_EDUCATION_FAILURE:
        case GET_EDUCATION_FAILURE:
        case UPDATE_EDUCATION_FAILURE:
        case DELETE_EDUCATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case 'RESET_EDUCATION_ERROR_STATE':
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
export const abilitiesReducer = (state = initialAbilitiesState, action) => {
    switch (action.type) {
        case SAVE_ABILITIES_REQUEST:
        case GET_ALL_ABILITIES_REQUEST:
        case GET_ABILITIES_REQUEST:
        case UPDATE_ABILITIES_REQUEST:
        case DELETE_ABILITIES_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case SAVE_ABILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                abilitiesList: [...state.abilitiesList, action.payload],
                error: null
            };
        case GET_ALL_ABILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                abilitiesList: action.payload,
                error: null
            };
        case GET_ABILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                abilities: action.payload,
                error: null
            };
        case UPDATE_ABILITIES_SUCCESS:
        case DELETE_ABILITIES_SUCCESS:
            return {
                ...state,
                abilitiesList: state.abilitiesList.filter((abilities) => abilities.id !== action.payload),
                isLoading: false,
                error: null
            };

        case SAVE_ABILITIES_FAILURE:
        case GET_ALL_ABILITIES_FAILURE:
        case GET_ABILITIES_FAILURE:
        case UPDATE_ABILITIES_FAILURE:
        case DELETE_ABILITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case 'RESET_ABILITIES_ERROR_STATE':
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
export const certificationReducer = (state = initialCertificationState, action) => {
    switch (action.type) {
        case SAVE_CERTIFICATION_REQUEST:
        case GET_ALL_CERTIFICATION_REQUEST:
        case GET_CERTIFICATION_REQUEST:
        case UPDATE_CERTIFICATION_REQUEST:
        case DELETE_CERTIFICATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case SAVE_CERTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                certification: [...state.certification, action.payload],
                error: null
            };
        case GET_ALL_CERTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                certification: action.payload,
                error: null
            };
        case GET_CERTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                certification: action.payload,
                error: null
            };
        case UPDATE_CERTIFICATION_SUCCESS:
        case DELETE_CERTIFICATION_SUCCESS:
            return {
                ...state,
                certification: state.certification.filter((certification) => certification.id !== action.payload),
                isLoading: false,
                error: null
            };

        case SAVE_CERTIFICATION_FAILURE:
        case GET_ALL_CERTIFICATION_FAILURE:
        case GET_CERTIFICATION_FAILURE:
        case UPDATE_CERTIFICATION_FAILURE:
        case DELETE_CERTIFICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case 'RESET_CERTIFICATION_ERROR_STATE':
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export const inHouseProjectReducer = (state = initialInHouseProjectState, action) => {
    switch (action.type) {
        case GET_ALL_INHOUSE_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case GET_ALL_INHOUSE_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                inHouseProjects: action.payload,
                success: true
            };
        case GET_ALL_INHOUSE_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case SAVE_INHOUSE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case SAVE_INHOUSE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true

                // inHouseProjects: [...state.inHouseProjects, action.payload]
            };
        case SAVE_INHOUSE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_INHOUSE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case GET_INHOUSE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,

                inHouseProject: action.payload
            };
        case GET_INHOUSE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                success: true,

                error: action.payload
            };
        case UPDATE_INHOUSE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case UPDATE_INHOUSE_PROJECT_SUCCESS:
            const updatedInHouseProject = action.payload;
            const updatedInHouseProjects = state.inHouseProjects.map((inHouseProject) =>
                inHouseProject.id === updatedInHouseProject.id ? updatedInHouseProject : inHouseProject
            );
            return {
                ...state,
                loading: false,
                success: true,
                inHouseProjects: updatedInHouseProjects,
                inHouseProject: updatedInHouseProject
            };
        case UPDATE_INHOUSE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,

                error: action.payload
            };
        case DELETE_INHOUSE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case DELETE_INHOUSE_PROJECT_SUCCESS:
            return {
                ...state,
                inHouseProjects: state.inHouseProjects.filter((project) => project.id !== action.payload),
                loading: false,
                success: true
            };

        case DELETE_INHOUSE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,

                error: action.payload
            };
        case 'RESET_INHOUSE_ERROR_STATE':
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export const otherProjectReducer = (state = initialOtherProjectState, action) => {
    switch (action.type) {
        case SAVE_OTHER_PROJECT_REQUEST:
        case GET_ALL_OTHER_PROJECTS_REQUEST:
        case GET_OTHER_PROJECT_REQUEST:
        case UPDATE_OTHER_PROJECT_REQUEST:
        case DELETE_OTHER_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SAVE_OTHER_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                otherProjects: [...state.otherProjects, action.payload]
            };
        case GET_ALL_OTHER_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                otherProjects: action.payload
            };
        case GET_OTHER_PROJECT_SUCCESS:
        case UPDATE_OTHER_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                otherProject: action.payload
            };
        case DELETE_OTHER_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                otherProjects: state.otherProjects.filter((project) => project._id !== action.payload)
            };
        case SAVE_OTHER_PROJECT_FAILURE:
        case GET_ALL_OTHER_PROJECTS_FAILURE:
        case GET_OTHER_PROJECT_FAILURE:
        case UPDATE_OTHER_PROJECT_FAILURE:
        case DELETE_OTHER_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'RESET_OTHER_ERROR_STATE':
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export const resumeReducer = (state = initialResumeState, action) => {
    switch (action.type) {
        case DELETE_RESUME_REQUEST:
        case GET_RESUME_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_RESUME_SUCCESS:
        case GET_RESUME_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case DELETE_RESUME_FAILURE:
        case GET_RESUME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export const getResumeIdReducer = (state = { resumeId: 0 }, action) => {
    switch (action.type) {
        case 'FETCH_RESUME_ID':
            return {
                resumeId: action.payload,
                message: 'Success'
            };

        default:
            return state;
    }
};
