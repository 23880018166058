import { useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'layout/MainLayout';
import { GoogleOAuthProvider } from '@react-oauth/google';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Switch,
    Typography,
    useMediaQuery,
    Container,
    Avatar,
    ButtonBase
} from '@mui/material';
import TextField from '@mui/material/TextField';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axiosConn from 'views/pages/authentication/auth-forms/AxiosConn';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useDispatch } from 'react-redux';
import { Route, useNavigate, Routes, BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import NotFound from 'views/errorResponse/NotFound';
import { createRoutes } from 'routes/MainRoutes';
import Google from 'assets/images/icons/social-google.svg';
import Alert from '@mui/material/Alert';
import GoogleLogin from './GoogleLogin';
import App from './GoogleLogin';
import apiEndPoints from '../../../../constants/api';
const { hostname } = apiEndPoints;
import sakhalogo from '../../../../assets/images/SakhaLogo.png';
import LogoSection from 'layout/MainLayout/LogoSection';
import { IconMenu2 } from '@tabler/icons';
import logo from 'assets/images/Logo.png';
import resumeImage from 'assets/images/ResumeImage.png';
// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [checked, setChecked] = useState(true);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [createdRoute, setCreatedRoute] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [errors, setErrors] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setEmailError('');
    }, [email]);

    useEffect(() => {
        setPasswordError('');
    }, [password]);

    return (
        <>
            {!localStorage.getItem('isLoggedIn') ? (
                <>
                    {/* logo & toggler button */}
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingBottom: theme.spacing(1),
                            paddingTop: theme.spacing(1),
                            backgroundColor: theme.palette.background.default,
                            boxShadow: theme.shadows[1],
                            position: 'sticky',
                            top: 0,
                            zIndex: 999
                        }}
                    >
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <img src={sakhalogo} alt="sakha Logo" height={60} />
                            </Box>

                            <Box sx={{ marginLeft: 70 }}>
                                <img src={logo} alt="quick Logo" height={50} />
                            </Box>
                        </Box>
                        {/* <Box sx={{marginRight:3}}>
                            <GoogleOAuthProvider clientId="446824100454-fa124uv5l8oo6r4fhucst3jmmmgl1v5s.apps.googleusercontent.com">
                                <App title={'Sign in'} style={'Signin'} />
                            </GoogleOAuthProvider>
                        </Box> */}
                        {/* <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                marginRight: theme.spacing(2),
                                '&:hover': {
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark
                                }
                            }}
                        >
                            Sign In
                        </Button> */}
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh" mt={5}>
                        <Grid container >
                            {/* Left Box */}
                            <Grid item xs={12} md={6}>
                                <Box p={12} pl={40} display="flex" flexDirection="column" justifyContent="center">
                                    <Box mb={3} ml={-2}>
                                        <img src={logo} alt="quick Logo" height={70} />
                                    </Box>
                                    <Typography variant="h5" sx={{ color: theme.palette.secondary.dark, fontWeight: 'bold' }}>
                                        Fast. Easy. Effective.
                                    </Typography>
                                    <Typography variant="h3" sx={{ mt: 2 }}>
                                        Empower Your Workforce,
                                        <br/> Elevate Your HR
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 2 }}>
                                        Building Resumes Made Easy!
                                    </Typography>

                                    <GoogleOAuthProvider clientId="446824100454-fa124uv5l8oo6r4fhucst3jmmmgl1v5s.apps.googleusercontent.com">
                                        <App style={'GetStarted'} />
                                    </GoogleOAuthProvider>
                                </Box>
                            </Grid>

                            {/* Right Box */}
                            <Grid item xs={12} md={6}>
                                <Box mr={4}>
                                    <img src={resumeImage} alt="quick Logo" style={{ width: '100%', height: 'auto' }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                        {/* Custom login form */}
                    
                     {/* <Box
                        minHeight="100vh"
                        bgcolor="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow={4}
                        borderRadius={4}
                        px={4}
                    >
                        <Box maxWidth="480px" width="100%" px={4} py={6} boxShadow={3} borderRadius={4} bgcolor="white">
                            <Box mb={4}>
                                <img src={sakhalogo} alt="Sakha Global Logo" style={{ maxWidth: '50%' }} />
                            </Box>

                            <Typography variant="h2" color="secondary" align="center" mb={2}>
                                QuickCV
                            </Typography>

                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    submit: null
                                }}
                                onSubmit={(e) => {
                                    if (!email || email.trim() === '') {
                                        setEmailError('Email is required');
                                        setIsEmailValid(false);
                                    } else {
                                        setIsEmailValid(true);
                                    }

                                    if (!password || password.trim() === '') {
                                        setPasswordError('Password is required');
                                        setIsPasswordValid(false);
                                    } else {
                                        setIsPasswordValid(true);
                                        message = others.login({ email, password });
                                    }
                                }}
                            >
                                {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit} {...others}>
                                        <Box sx={{ mb: 2 }}>
                                            <TextField
                                                fullWidth
                                                error={!!emailError}
                                                helperText={emailError}
                                                sx={{ mt: 3 }}
                                                label="Email Address"
                                                variant="outlined"
                                                id="outlined-adornment-email-login"
                                                type="email"
                                                value={email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={(e) => setEmail(e.target.value)}
                                                // InputProps={{ inputProps: { onKeyPress: handleKeyPress, onPaste: handlePaste ,onContextMenu: (event) => {
                                                //     event.preventDefault();
                                                // }} }}
                                            />
                                        </Box>
                                        <Box sx={{ mb: 2 }}>
                                            <TextField
                                                fullWidth
                                                error={!!passwordError}
                                                sx={{ mt: 2 }}
                                                label="Password"
                                                variant="outlined"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                name="password"
                                                onBlur={handleBlur('password')}
                                                onChange={(e) => setPassword(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                helperText={passwordError}
                                            />
                                            {emailError || passwordError ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <Box sx={{ color: '#FF0000', fontSize: '0.8rem', marginLeft: '-4%', marginTop: '5%' }}>
                                                        <ul>{others.message ? <li>{others.message}</li> : <></>}</ul>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>

                                        <Box sx={{ mt: 3 }}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                // onClick={handleSubmit}
                                            >
                                                <Typography sx={{ color: 'white', variant: 'h2' }}>Login</Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <AnimateButton>
                                                <GoogleOAuthProvider clientId="446824100454-fa124uv5l8oo6r4fhucst3jmmmgl1v5s.apps.googleusercontent.com">
                                                    
                                                    <App />
                                                </GoogleOAuthProvider>
                                            </AnimateButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box> */}
                </>
            ) : (
                <>{navigate('/dashboard')}</>
            )}
        </>
    );
};

export default FirebaseLogin;
