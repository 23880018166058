import React, { useEffect, useState, useTheme } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Divider, TablePagination, TableSortLabel, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import { SHOW_MENU } from 'action/PermissionAction';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import apiEndPoints from '../../../../constants/api';
import { useMediaQuery, Hidden } from '@mui/material';
import PropTypes from "prop-types";
import ResumeDownloadModal from '../TemplatePreview/ResumeDownloadModal';
const { hostname } = apiEndPoints;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex" left={60}>
        <CircularProgress variant="determinate" thickness={5} style={{'color': '#5e35b1'}} {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            color='black'
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
  };

export default function Resume() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [resume, setResume] = useState();
    const [resumeId, setResumeId] = useState();
    const [resumeList, setResumeList] = useState([]);
    const [totalPage, setTotalPages] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(window.localStorage.getItem('rowsPerPage') || 10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isAscending, setIsAscending] = useState(true);
    const [sortBy, setSortBy] = useState('modifiedDate');
    const [firstname, setFirstName] = useState(null);
    const [lastname, setLastName] = useState('');
    const [designation, setDesignation] = useState(0);
    const [searchByNameValue, setSearchByNameValue] = useState(null);
    const [searchByEmployeeId, setSearchByEmployeeId] = useState(null);
    const [searchdesignationValue, setSearchDesignationValue] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const axiosConn = useAxiosInterceptors();
    const [page, setPage] = useState(() => {
        return parseInt(localStorage.getItem('resumecurrentPage') || 0);
    });
    const [designationOptions, setDesignationOptions] = useState([]);

    const setdata = (res) => {
        const sort = JSON.parse(localStorage.getItem('sorting')) || {};
        if (res.length > 0 && Object.keys(sort).length > 0) handleSortClick(res, sort.columnName, sort.isAscending);
        else setResumeList(res);
        setIsLoading(false);
        setTotalRecords(res.length);
    };
    const fetchResume = async (name, empId, desig) => {
        try {
            console.log('formfields', name, desig, empId);
            let requestUrl = `${hostname}/api/v1/Resume`;

            if (name || desig || empId) {
                let params = {};
                if (name) {
                    const nameParts = name.split(' ');
                    if (nameParts.length > 1) {
                        params.Firstname = nameParts[0];
                        params.Lastname = nameParts[nameParts.length - 1];
                    } else {
                        params.Firstname = name;
                        params.Lastname = name;
                    }
                }
                // if (name) params.Firstname = name;
                // if (name) params.Lastname = name;
                if (desig) params.Designation = desig;
                if (empId) params.EmployeeId = empId;

                requestUrl += `?${new URLSearchParams(params).toString()}`;
            }

            try {
                const res = await axiosConn.get(requestUrl);

                setdata(res.data.data);
                setIsLoading(false);
            } catch (err) {
                console.log('An error occurred during the API request:', err);
                setTotalRecords(0);
            }
        } catch (err) {
            console.log('empty result set.. catch block!!');
            console.log(err);
        }
    };

    useEffect(() => {
        localStorage.setItem('resumecurrentPage', page.toString());
        fetchResume();
    }, [page]);

    console.log('resumeList', resumeList, designation);

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        window.localStorage.setItem('rowsPerPage', newRowsPerPage);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        window.localStorage.setItem('resumecurrentPage', newPage);
    };

    const handleSearchPage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearchClick = (event) => {
        //event.preventDefault();
        setPage(0);
        console.log('search button clicked');
        if (searchByNameValue != null) {
            console.log('setting firstname to', searchByNameValue);
            setSearchByNameValue(searchByNameValue);
        }
        if (searchByEmployeeId != null) {
            setSearchByEmployeeId(searchByEmployeeId);
        }
        if (searchdesignationValue != null) {
            console.log('setting designation to', searchdesignationValue);
            setDesignation(searchdesignationValue);
        }
        fetchResume(searchByNameValue, searchByEmployeeId, searchdesignationValue);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            setPage(0);
            if (searchByNameValue !== '') {
                console.log('setting firstname to', searchByNameValue);
                setSearchByNameValue(searchByNameValue);
            } else {
                setSearchByNameValue(null);
            }
            if (searchByEmployeeId !== '') {
                setSearchByEmployeeId(searchByEmployeeId);
            } else {
                setSearchByEmployeeId(null);
            }
            if (searchdesignationValue !== '') {
                setDesignation(searchdesignationValue);
            }
            fetchResume(searchByNameValue, searchByEmployeeId, searchdesignationValue);
        }
    };
    const handleKeyPress = (event) => {
        const cursorPosition = event.target.selectionStart;
        const isFirstChar = cursorPosition === 0;
        const isSpace = event.key === ' ';
        if (isFirstChar && isSpace) {
            event.preventDefault();
        }
    };
    const handleSortClick = (resumedata, columnName, isAscending) => {
        setSortBy(columnName);
        setIsAscending(isAscending);

        const sortedResumeList = [...resumedata];

        sortedResumeList.sort((a, b) => {
            if (!isAscending) {
                [a, b] = [b, a];
            }
            switch (columnName) {
                case 'name':
                    return a.firstname.localeCompare(b.firstname);
                case 'empId':
                    return parseInt(a.employeeId) - parseInt(b.employeeId);
                case 'modifiedDate':
                    return new Date(a.modifiedDate) - new Date(b.modifiedDate);
                case 'percentageCompleted':
                    return a.percentageCompleted - b.percentageCompleted;
                case 'designation':
                default:
                    return a.designation.localeCompare(b.designation);
            }
        });

        // Set the sorted resume list
        setResumeList(sortedResumeList);
        localStorage.setItem('sorting', JSON.stringify({ columnName, isAscending }));
    };

    const handleClear = () => {
        setSearchByNameValue('');
        setFirstName('');
        setSearchByEmployeeId('');
        setLastName('');
        setSearchDesignationValue(0);
        setDesignation(0);
        fetchResume();
    };

    const options =
        designationOptions.length > 0
            ? designationOptions.map((designation) => {
                  return { value: designation.id, label: designation.name };
              })
            : [];

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Designation/active`)
            .then((response) => {
                setDesignationOptions(response.data.data);
                console.log('Designation', response);
            })
            .catch((error) => console.log(error));
    }, []);
    useEffect(() => {
        dispatch(SHOW_MENU(false));
    }, []);
    console.log('totalRecords', totalRecords);

    const downloadResume = async (resumeId, Name) => {
        try {
            const res = await axiosConn.get(`${hostname}/api/v1/Resume/DownloadResumeData/${resumeId}`, {
                responseType: 'blob' // set response type to blob
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Name}.docx`);
            console.log('filename', Name); // set file name and type
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log('not downloading.....', error);
        }
    };

    const handleSubmit = (event, resumeId, Name) => {
        downloadResume(resumeId, Name);
    };

    return (
        <Box mt={7}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isSmallScreen ? 'center' : 'space-evenly',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    mt: 2
                }}
            >
                {isLoading ? (
                    <>
                        <Typography mt={10} color="secondary.main">
                            <CircularProgress />
                        </Typography>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    background: 'transparent',
                                    position: 'relative'
                                }}
                            >
                                <Grid container direction="row" justifyContent={{ xs: 'center', md: 'space-between' }} mb="2%">
                                    {isSmallScreen ? (
                                        <Stack direction="column" alignItems="center" spacing={1}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <TextField
                                                    sx={{ minWidth: '130px' }}
                                                    size="small"
                                                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, maxLength: 30 } }}
                                                    label="Employee ID"
                                                    value={searchByEmployeeId || ''}
                                                    type="search"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchByEmployeeId('');
                                                            console.log('on change: search', searchByEmployeeId);
                                                        } else {
                                                            setSearchByEmployeeId(e.target.value);
                                                            console.log('on change: searchValue', searchByEmployeeId);
                                                            console.log('on change: search', lastname);
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <TextField
                                                    sx={{ minWidth: '130px' }}
                                                    size="small"
                                                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, maxLength: 30 } }}
                                                    label="Name"
                                                    value={searchByNameValue || ''}
                                                    type="search"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchByNameValue('');
                                                            console.log('on change: search', searchByNameValue);
                                                        } else {
                                                            setSearchByNameValue(e.target.value);
                                                            console.log('on change: searchValue', searchByNameValue);
                                                            console.log('on change: search', firstname);
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <TextField
                                                    displayEmpty
                                                    labelId="my-select-label"
                                                    select
                                                    id="my-select"
                                                    size="small"
                                                    value={searchdesignationValue || ''}
                                                    label="Designation"
                                                    sx={{ width: '250px' }}
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchDesignationValue('');
                                                            console.log('on change: search', searchdesignationValue);
                                                        } else {
                                                            setSearchDesignationValue(e.target.value);
                                                            console.log('on change: searchValue', searchdesignationValue);
                                                            console.log('on change: search', designation);
                                                        }
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200,
                                                                    overflowY: 'auto'
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                >
                                                    {options.map(({ value, label }, index) => (
                                                        <MenuItem key={index} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <div>
                                                    <Button
                                                        sx={{ display: 'flex' }}
                                                        variant="outlined"
                                                        color="dark"
                                                        size="large"
                                                        onClick={(e) => {
                                                            handleSearchClick(e);
                                                        }}
                                                    >
                                                        <SearchSharpIcon fontSize="medium" />
                                                    </Button>
                                                </div>
                                                {!isSmallScreen && (
                                                    <div>
                                                        <Tooltip title="Clear All">
                                                            <Button
                                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                                variant="outlined"
                                                                color="dark"
                                                                size="large"
                                                                onClick={handleClear}
                                                            >
                                                                <ClearIcon fontSize="medium" />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </Stack>
                                        </Stack>
                                    ) : (
                                        <>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <TextField
                                                    sx={{ minWidth: '250px' }}
                                                    size="small"
                                                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, maxLength: 30 } }}
                                                    label="Employee ID"
                                                    value={searchByEmployeeId || ''}
                                                    type="search"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchByEmployeeId('');
                                                            console.log('on change: search', searchByEmployeeId);
                                                        } else {
                                                            setSearchByEmployeeId(e.target.value);
                                                            console.log('on change: searchValue', searchByEmployeeId);
                                                            console.log('on change: search', lastname);
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <TextField
                                                    sx={{ minWidth: '250px' }}
                                                    size="small"
                                                    InputProps={{ inputProps: { onKeyPress: handleKeyPress, maxLength: 30 } }}
                                                    label="Name"
                                                    value={searchByNameValue || ''}
                                                    type="search"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchByNameValue('');
                                                            console.log('on change: search', searchByNameValue);
                                                        } else {
                                                            setSearchByNameValue(e.target.value);
                                                            console.log('on change: searchValue', searchByNameValue);
                                                            console.log('on change: search', firstname);
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <TextField
                                                    displayEmpty
                                                    labelId="my-select-label"
                                                    select
                                                    id="my-select"
                                                    size="small"
                                                    value={searchdesignationValue || ''}
                                                    label="Designation"
                                                    sx={{ width: '250px', marginLeft: 1 }}
                                                    onChange={(e) => {
                                                        if (e.target.value === '') {
                                                            setSearchDesignationValue('');
                                                            console.log('on change: search', searchdesignationValue);
                                                        } else {
                                                            setSearchDesignationValue(e.target.value);
                                                            console.log('on change: searchValue', searchdesignationValue);
                                                            console.log('on change: search', designation);
                                                        }
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200,
                                                                    overflowY: 'auto'
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                >
                                                    {options.map(({ value, label }, index) => (
                                                        <MenuItem key={index} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <div>
                                                    <Button
                                                        sx={{ display: 'flex' }}
                                                        variant="outlined"
                                                        color="dark"
                                                        size="large"
                                                        onClick={(e) => {
                                                            handleSearchClick(e);
                                                        }}
                                                    >
                                                        <SearchSharpIcon fontSize="medium" />
                                                    </Button>
                                                </div>
                                                {!isSmallScreen && (
                                                    <div>
                                                        <Tooltip title="Clear All">
                                                            <Button
                                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                                variant="outlined"
                                                                color="dark"
                                                                size="large"
                                                                onClick={handleClear}
                                                            >
                                                                <ClearIcon fontSize="medium" />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </Stack>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                            {totalRecords !== 0 ? (
                                <>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    background: 'transparent',
                                                    position: 'relative',
                                                    zindex: 1
                                                }}
                                            >
                                                <Paper sx={{ overflowX: 'auto' }}>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>
                                                                        <TableSortLabel
                                                                            active={sortBy === 'empId'}
                                                                            direction={isAscending ? 'asc' : 'desc'}
                                                                            onClick={() =>
                                                                                handleSortClick(resumeList, 'empId', !isAscending)
                                                                            }
                                                                            sx={{
                                                                                color: 'secondary.light',
                                                                                '&:hover': {
                                                                                    color: 'secondary.light'
                                                                                }
                                                                            }}
                                                                        >
                                                                            Employee ID
                                                                        </TableSortLabel>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <TableSortLabel
                                                                            active={sortBy === 'name'}
                                                                            direction={isAscending ? 'asc' : 'desc'}
                                                                            onClick={() =>
                                                                                handleSortClick(resumeList, 'name', !isAscending)
                                                                            }
                                                                            sx={{
                                                                                color: 'secondary.light',
                                                                                '&:hover': {
                                                                                    color: 'secondary.light'
                                                                                }
                                                                            }}
                                                                        >
                                                                            Name
                                                                        </TableSortLabel>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <TableSortLabel
                                                                            active={sortBy === 'designation'}
                                                                            direction={isAscending ? 'asc' : 'desc'}
                                                                            onClick={() =>
                                                                                handleSortClick(resumeList, 'designation', !isAscending)
                                                                            }
                                                                            sx={{
                                                                                color: 'secondary.light',
                                                                                '&:hover': {
                                                                                    color: 'secondary.light'
                                                                                }
                                                                            }}
                                                                        >
                                                                            Designation
                                                                        </TableSortLabel>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <TableSortLabel
                                                                            active={sortBy === 'modifiedDate'}
                                                                            direction={isAscending ? 'asc' : 'desc'}
                                                                            onClick={() =>
                                                                                handleSortClick(resumeList, 'modifiedDate', !isAscending)
                                                                            }
                                                                            sx={{
                                                                                color: 'secondary.light',
                                                                                '&:hover': {
                                                                                    color: 'secondary.light'
                                                                                }
                                                                            }}
                                                                        >
                                                                            Updated Date
                                                                        </TableSortLabel>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <TableSortLabel
                                                                            active={sortBy === 'percentageCompleted'}
                                                                            direction={isAscending ? 'asc' : 'desc'}
                                                                            onClick={() =>
                                                                                handleSortClick(resumeList, 'percentageCompleted', !isAscending)
                                                                            }
                                                                            sx={{
                                                                                color: 'secondary.light',
                                                                                '&:hover': {
                                                                                    color: 'secondary.light'
                                                                                }
                                                                            }}
                                                                        >
                                                                            PercentageCompleted
                                                                        </TableSortLabel>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell></StyledTableCell>
                                                                    {/* <StyledTableCell></StyledTableCell> */}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {resumeList
                                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    .map((resume) => {
                                                                        const dateObject = new Date(resume.modifiedDate);
                                                                        const options = { month: 'long', day: 'numeric', year: 'numeric' };
                                                                        const formattedModifiedDate = dateObject
                                                                            .toLocaleDateString('en-US', options)
                                                                            .replace(/,/g, '-');
                                                                        const parts = formattedModifiedDate.split(' ');
                                                                        parts[0] += ' - ';
                                                                        const modifiedDate = parts.join(' ');

                                                                        return (
                                                                            <StyledTableRow>
                                                                                <StyledTableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    sx={{ width: '20%' }}
                                                                                >
                                                                                    {resume.employeeId}
                                                                                </StyledTableCell>
                                                                                <StyledTableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    sx={{ width: '20%' }}
                                                                                >
                                                                                    {resume.firstname} {resume.lastname}
                                                                                </StyledTableCell>

                                                                                <StyledTableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    sx={{ width: '20%' }}
                                                                                >
                                                                                    {resume.designation}
                                                                                </StyledTableCell>
                                                                                <StyledTableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    sx={{ width: '20%' }}
                                                                                >
                                                                                    {modifiedDate}
                                                                                </StyledTableCell>
                                                                                <StyledTableCell
                                                                                component="th"
                                                                                scope="row"
                                                                                sx={{ width: '20%' }}
                                                                                >
                                                                                <CircularProgressWithLabel value={resume.percentageCompleted} />
                                                                                </StyledTableCell>
                                                                                <StyledTableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    sx={{ mr: '5%', display: 'flex' }}
                                                                                >
                                                                                    <Button
                                                                                        color="dark"
                                                                                        onClick={(e) => {
                                                                                            console.log(
                                                                                                'Onclick',
                                                                                                `/basicinfo/${resume.id}`
                                                                                            );
                                                                                            navigate(`/basicinfo/${resume.id}`);
                                                                                            localStorage.setItem('resumeId', resume.id);
                                                                                        }}
                                                                                    >
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                    {/* <Button
                                                                                color="dark"
                                                                                onClick={(e) => {
                                                                                    console.log('Onclick', ``);
                                                                                    handleSubmit(
                                                                                        e,
                                                                                        resume.id,
                                                                                        `${resume.firstname} ${resume.lastname}`
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DownloadIcon />
                                                                            </Button> */}
                                                                                    <ResumeDownloadModal
                                                                                        resumeId={resume.id}
                                                                                        name={`${resume.firstname} ${resume.lastname}`}
                                                                                    />
                                                                                </StyledTableCell>
                                                                            </StyledTableRow>
                                                                        );
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                        <Divider />
                                                        {rowsPerPage && totalRecords && (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                component="div"
                                                                count={totalRecords}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handlePageChange}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        )}
                                                        {/* {!firstname || !lastname || !designation ? (
                                                    <>
                                                        {rowsPerPage && totalRecords && (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                component="div"
                                                                count={totalRecords}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handlePageChange}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )} */}
                                                    </TableContainer>{' '}
                                                </Paper>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    background: 'transparent',
                                                    position: 'relative',
                                                    zindex: 1,
                                                    minWidth: '80%'
                                                }}
                                            >
                                                <Paper sx={{ minWidth: '80%' }}>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>Employee ID</StyledTableCell>
                                                                    <StyledTableCell>Name</StyledTableCell>
                                                                    <StyledTableCell>Designation</StyledTableCell>
                                                                    <StyledTableCell>Updated Date</StyledTableCell>
                                                                    <StyledTableCell>Percentage Completed</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell align="center" colSpan={5} sx={{ width: '500px' }}>
                                                                        <Typography
                                                                            style={{ color: '#ff0000' }}
                                                                            fontWeight="bold"
                                                                            sx={{ ml: '5%' }}
                                                                        >
                                                                            No records found
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
