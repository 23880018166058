// assets
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import MyResumeIcon from '@mui/icons-material/ContactPage';
import { useSelector } from 'react-redux';

const dashboardMenu = () => {
    const icons = { LibraryBooksOutlinedIcon, DashboardOutlinedIcon };
    const permissions = useSelector((state) => state.fetchPermissions.permissions);

    const dashboard = {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'group',
        children: []
    };
    if (permissions.includes('dashboard.analytics.view')) {
        dashboard.children.push({
            id: 'default',
            title: 'Analytics',
            type: 'item',
            icon: icons.DashboardOutlinedIcon,
            url: '/dashboard'
        });
    }

    if (permissions.includes('resume.all.filter.view')) {
        dashboard.children.push({
            id: 'resume',
            title: 'Resumes',
            type: 'item',
            url: '/resumes',
            icon: icons.LibraryBooksOutlinedIcon,
            breadcrumbs: false
        });
    }

    if (permissions.includes('dashboard.resume.view')) {
        dashboard.children.push({
            id: 'resume',
            title: 'Resume',
            type: 'item',
            url: '/dashboard',
            icon: icons.LibraryBooksOutlinedIcon,
            breadcrumbs: false
        });
    }

    return dashboard;
};

export default dashboardMenu;
