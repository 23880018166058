import { useSelector } from "react-redux";

export const checkPermissions = (param) => {
 //   const value = localStorage.getItem("Permissions");
    const permissions = useSelector((state) => state.fetchPermissions.permissions);
    console.log("Permissions",permissions);
    if(permissions.includes(param))
    {
  // console.log( permissions.some((item) => item.value === param));
  return true;
    }
    else{
        return false;
    }
};
