import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import TitleCard from 'ui-component/cards/Skeleton/Skeleton';
import SkeletonDesignationCard from 'ui-component/cards/Skeleton/DesignationCard';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import apiEndPoints from '../../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
const { hostname } = apiEndPoints;

const CardWrapper = styled(TitleCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
}));

const DesignationCard = ({ isLoading }) => {
  const theme = useTheme();
  const [designationCount, setDesignationCount] = useState([]);
  const axiosConn = useAxiosInterceptors();

  useEffect(() => {
    if (!isLoading) {
      axiosConn.get(`${hostname}/api/v1/Dashboard/DesignationCount`)
        .then((response) => {
            console.log('DesignationCard',response)
            setDesignationCount(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <SkeletonDesignationCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <PortraitOutlinedIcon sx={{ color: theme.palette.common.white }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                    {designationCount.length > 0 ? designationCount : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 1.25 }}>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 700,
                    color: theme.palette.common.white,
                  }}
                >
                  Designations
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

export default DesignationCard;
