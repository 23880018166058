export const showSnackbar = (message, severity) => {
    return {
        type: 'SHOW_SNACKBAR',
        payload: { message, severity }
    };
};

export const hideSnackbar = () => {
    return {
        type: 'HIDE_SNACKBAR'
    };
};


