import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import FirebaseLogin from 'views/pages/authentication/auth-forms/AuthLogin';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Designation = Loadable(lazy(() => import('views/pages/admin/Designation/Designation')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <FirebaseLogin />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        }
    ]
    
};


