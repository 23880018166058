const PermissionReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GENERATE_MENU':
            return {
                permissions: action.payload,
                message: 'Success'
            };
        case 'RESET_MENU':
            return {
                permissions: null,
                message: 'Success'
            };

        default:
            return state;
    }
};

export default PermissionReducer;
