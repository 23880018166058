import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from '../../action/SnackbarAction';
import { Alert } from '@mui/material';
import { Box } from '@mui/system';

const SnackbarProvider = () => {
    const dispatch = useDispatch();
    const { open, message, severity } = useSelector((state) => state.customSnackbar);

    const handleClose = () => {
        dispatch(hideSnackbar());
    };

    return (
        <>
            {severity ? (
                <>
                    <Box>
                        <Snackbar
                            open={open}
                            autoHideDuration={severity === 'success' ? 2000 : null}
                            onClose={handleClose}
                            sx={{
                                bottom: {
                                    xs: 90,
                                    sm: 0,
                                    md: severity === 'error' ? '75%' : '80%'
                                },
                                left: {
                                    xs: 90,
                                    sm: 0,
                                    md: severity === 'error' ? '45%' : '72%'
                                }
                            }}
                        >
                            <Alert
                                variant="filled"
                                onClose={handleClose}
                                severity={severity ? severity : undefined}
                                sx={{ width: '400px' }}
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default SnackbarProvider;
