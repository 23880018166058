import React from 'react';
import { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Google from 'assets/images/icons/social-google.svg';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { createRoutes } from '../../../../routes/MainRoutes';
import NotFound from 'views/errorResponse/NotFound';
import MainLayout from 'layout/MainLayout';
import apiEndPoints from '../../../../constants/api';
const { hostname } = apiEndPoints;
import { Box, Button, useMediaQuery, Alert, Snackbar } from '@mui/material';
import useScriptRef from 'hooks/useScriptRef';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from 'views/dashboard/Default';
import { Login } from '@mui/icons-material';
import { Fetch_PERMISSIONS } from 'action/PermissionAction';
import { store } from 'store';
import { getBasicInfo } from 'action/ResumeAction';
import { FETCH_TOKEN_SUCCESS } from 'action/TokenAction';
import { useDispatch } from 'react-redux';
import Resume from 'views/pages/admin/Resume/Resume';

function App(props) {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [createdRoutes, setRoutes] = useState([]);
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [userId, setUserId] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [claims, setClaims] = useState(null);
    const [idtoken, setIdToken] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.tokenReducer.token);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                setIsLoggedIn(window.localStorage.getItem('isLoggedIn'));
                setIsAdmin(window.localStorage.getItem('isAdmin'));
                const createdRoute = createRoutes(token.claims);
                createdRoute.then((res) => {
                    setRoutes(res);
                });
            }
        };
        fetchData();
    }, []);

    const getResumeId = (userId) => {
        return new Promise((resolve, reject) => {
            fetch(`${hostname}/api/v1/Resume/resumestatus?userId=${userId}`)
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem('resumeId', data);
                    window.localStorage.setItem('isLoggedIn', true);
                    resolve(data); // Resolve the promise with the resumeId
                })
                .catch((error) => {
                    console.error('Error fetching resumeId:', error);
                    reject(error); // Reject the promise with the error
                });
        });
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) =>
            axios
                .post(`${hostname}/api/v1/User/google?accesstoken=${codeResponse.access_token}`)
                .then((res) => {
                    dispatch(FETCH_TOKEN_SUCCESS(res.data));

                    const createdRoute = createRoutes(res.data.claims);
                    createdRoute.then((res) => {
                        setRoutes(res);
                    });
                    window.localStorage.setItem('isLoggedIn', true);
                    window.localStorage.setItem('isAdmin', res.data.isAdmin);
                    window.localStorage.setItem('token', res.data.accessToken);
                    const { userId, firstName, lastName, email } = res.data;
                    const details = { userId, firstName, lastName, email };
                    window.localStorage.setItem('details', JSON.stringify(details));
                    const permission = res.data.claims.map((obj) => obj.value);
                    window.localStorage.setItem('Permissions', JSON.stringify(permission));

                    store.dispatch(Fetch_PERMISSIONS(permission));
                    getResumeId(details.userId).then((resumeId) => {
                        window.localStorage.setItem('resumeId', resumeId);

                        dispatch(getBasicInfo(resumeId));
                        setIsAdmin(window.localStorage.getItem('isAdmin'));
                        setIsLoggedIn(window.localStorage.getItem('isLoggedIn'));
                        window.location.replace('/dashboard');
                    });
                })
                .catch((err) => {
                    setOpen(true);
                    setError('Email not found');
                }),
        onError: (error) => console.log('error while google login', error)
    });

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Routes>
                {isLoggedIn && (
                    <Route element={<MainLayout />}>
                        {isAdmin === 'true' ? (
                            <>
                                <Route path="/dashboard" element={<Resume />} />
                            </>
                        ) : (
                            <>
                                <Route path="/dashboard" element={<Dashboard />} />
                            </>
                        )}
                        {createdRoutes.length > 0 &&
                            createdRoutes.map((data) => {
                                return <Route key={data.path} path={data.path} element={<data.component />} />;
                            })}

                        <Route path="*" element={<NotFound />} />
                    </Route>
                )}
            </Routes>
            <GoogleOAuthProvider clientId="446824100454-fa124uv5l8oo6r4fhucst3jmmmgl1v5s.apps.googleusercontent.com">
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    sx={{
                        bottom: {
                            xs: 90,
                            sm: 0,
                            md: '90%'
                        },
                        left: {
                            xs: 90,
                            sm: 0,
                            md: '35%'
                        }
                    }}
                >
                    {error && (
                        <Alert severity="error" sx={{ width: '460px' }}>
                            {error}
                        </Alert>
                    )}
                </Snackbar>

                <AnimateButton>
                    <Button
                        fullWidth
                        onClick={() => {
                            login();
                        }}
                        size="large"
                        variant={props.style == 'Signin' ? 'outlined' : 'contained'}
                        color="secondary"
                        sx={{
                            ...(props.style === 'Signin'
                                ? {
                                      '&:hover': {
                                          background: theme.palette.secondary.light,
                                          color: theme.palette.secondary.dark
                                      }
                                  }
                                : {
                                      color: 'white',
                                      mt: 3,
                                      borderRadius: '50px',
                                      maxWidth: '300px',
                                      textTransform: 'none',
                                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                      '&:hover': {
                                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)'
                                      }
                                  })
                        }}
                    >
                        {/* <Box sx={{ mr: { xs: 1, sm: 2, width: 10 } }}>
                            <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                        </Box> */}
                        Build my resume &rarr;
                        {/* Create resume &rarr; */}
                    </Button>
                </AnimateButton>
            </GoogleOAuthProvider>
        </>
    );
}
export default App;
