import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import apiEndPoints from '../../../../constants/api';
const { hostname } = apiEndPoints;

const CustomModal = styled(Modal)(({ theme }) => ({
    // Override default styles for the modal
    // Set the background color to a non-transparent color
    background: 'white',
    // Set opacity to make it non-transparent
    opacity: 1,
    // Add any other custom styles you need
    '& .modal-content': {
        '& .close-icon': {
            cursor: 'pointer',
            position: 'absolute',
            top: '15px',
            right: '38px',
            zIndex: 1,
            backgroundColor: '#4527a0',
            color: '#fff',
            minWidth: '40px',
            maxWidth: '50px',
            borderRadius: '50%',
            width: 40,
            height: 40,
            '&:hover': {
                backgroundColor: '#4527a0'
            }
        }
    }
}));
// Custom styles for the template preview
const TemplatePreview = styled('div')({
    position: 'relative',
    cursor: 'pointer',
    padding: '8px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    // border: '2px solid #ddd', // Add border style
    borderRadius: '8px', // Add border radius
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.3s ease'
    },
    '&:hover img': {
        transform: 'scale(1.1)'
    }
});

// Custom styles for the overlay
const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: 1
    }
});

// Custom styles for the modal content
// const ModalContent = styled('div')(({ theme }) => ({
//   padding: theme.spacing(2),
//   margin: '50px', // Add margin to the modal content
//   marginRight: '60px',
//   maxHeight: 'calc(90vh - 100px)', // Subtract the height of the close button from the max height
//   overflowY: 'scroll',
//   '&::-webkit-scrollbar': {
//     width: '12px',
//   },
//   '&::-webkit-scrollbar-track': {
//     background: '#f1f1f1',
//     margin: '25px',
//   },
//   '&::-webkit-scrollbar-thumb': {
//     background: '#888',
//     borderRadius: '40px',
//   },
//   '&::-webkit-scrollbar-thumb:hover': {
//     background: '#555',
//   },
//   display: 'flex', // Add display flex to center the message vertically
//   alignItems: 'center', // Center the message vertically
//   justifyContent: 'center', // Center the message horizontally
//   fontSize: '50px', // Increase the font size of the message
// }));
const ModalContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),

   margin: '1px', // Add margin to the modal content
    maxHeight: 'calc(90vh - 32px)', // Subtract the vertical margins from the max height
    overflowY: 'auto', // Add vertical scrollbar when content overflows
    '&::-webkit-scrollbar': {
        width: '8px' // Adjust the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // Customize the color of the scrollbar thumb
        borderRadius: '4px'
        // Adjust the border radius of the scrollbar thumb
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555' // Customize the color of the scrollbar thumb on hover
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
        margin: '25px' // Set the background color of the scrollbar track
    }
}));

// Custom theme with white background for the button
const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff'
                }
            }
        }
    }
});

const CloseButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: '15px',
    right: '38px',
    zIndex: 1,
    backgroundColor: '#4527a0', // Remove the background color
    color: '#fff',
    minWidth: '40px',
    maxWidth: '50px',
    borderRadius: '50%', // Make the button circular
    width: 40, // Adjust the width to your desired size
    height: 40, // Adjust the height to your desired size
    '&:hover': {
        backgroundColor: '#4527a0'
    }
}));

const ResumeDownloadModal = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(localStorage.getItem('isModalOpen') === 'true' || false);
    const [templates, setTemplates] = useState([]);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const axiosConn = useAxiosInterceptors();
    const modalContentRef = useRef(null);

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Template/Preview/All`)
            .then((response) => {
                const templatesWithImages = response.data.data.map((template) => ({
                    ...template,
                    image: `data:image/png;base64, ${template.image}`,
                    description: `${template.description}`,
                    name: `${template.name}`
                }));
                setTemplates(templatesWithImages);
                setDescription(templatesWithImages);
                setName(templatesWithImages);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
        localStorage.setItem('isModalOpen', 'true');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem('isModalOpen', 'false');
    };

    const { resumeId } = props;

    const downloadResume = async (resumeId, templateId, fileType) => {
        try {
            const res = await axiosConn.get(
                `${hostname}/api/v1/Resume/DownloadResumeData/${resumeId}?templateId=${templateId}&fileType=${fileType}`,
                {
                    responseType: 'blob' // set response type to blob
                }
            );

            const downloadURL = URL.createObjectURL(res.data);
            const link = document.createElement('a');

            // Determine the file extension based on the fileType
            let fileExtension = 'docx';
            if (fileType === 'pdf') {
                fileExtension = 'pdf';
            }

            const currentDate = new Date().toLocaleDateString().replace(/\//g, '-');
            const fileName = `${props.name}_${currentDate}.${fileExtension}`;

            link.href = downloadURL;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(downloadURL);
        } catch (error) {
            console.log('Error occurred while downloading the resume:', error);
        }
    };

    const handleSubmit = (resumeId, templateId, fileType) => {
        downloadResume(resumeId, templateId, fileType);
    };

    const handleImageClick = (index) => {
        if (modalContentRef.current) {
            const images = modalContentRef.current.getElementsByClassName('template-image');
            if (index === templates.length - 1) {
                // Last image, scroll to top
                modalContentRef.current.scrollTop = 0;
            } else {
                // Scroll to the clicked image
                images[index].scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <div>
            <Button variant="text" onClick={openModal}>
                <DownloadIcon sx={{color:props.color}}/>
            </Button>
            <CustomModal open={isModalOpen} onClose={() => {}}>
                <ModalContent ref={modalContentRef} className="modal-content">
                    <CloseButton className="close-icon" onClick={closeModal}>
                        <CloseIcon />
                    </CloseButton>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : templates.length === 0 ? (
                        <div>No templates available</div>
                    ) : (
                        <>
                            <Grid container spacing={11}>
                                {templates.map((template, index) => (
                                    <Grid item key={template.id} xs={12} sm={6} md={4}>
                                        <TemplatePreview className="template-preview">
                                            <img
                                                src={template.image}
                                                alt={template.id}
                                                className="template-image"
                                                onClick={() => handleImageClick(index)}
                                            />
                                            <Overlay>
                                                <div style={{ marginTop: '8px' }}>
                                                    {template.supportedFileTypes.includes('pdf') && (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            sx={{ color: 'white' }}
                                                            onClick={() => handleSubmit(resumeId, template.id, 'pdf')}
                                                            style={{ marginRight: '8px' }}
                                                        >
                                                            <DownloadIcon style={{ marginRight: '4px' }} />
                                                            PDF
                                                        </Button>
                                                    )}
                                                    {template.supportedFileTypes.includes('docx') && (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            sx={{ color: 'white' }}
                                                            onClick={() => handleSubmit(resumeId, template.id, 'docx')}
                                                        >
                                                            <DownloadIcon style={{ marginRight: '4px' }} />
                                                            DOCX
                                                        </Button>
                                                    )}
                                                </div>
                                            </Overlay>
                                        </TemplatePreview>
                                        <div style={{ marginLeft: "10px",fontWeight:'bold',color:'#111936'}}>
                                            <div style={{fontSize:'20px'}}>Name:{template.name}</div>
                                            <div>{template.description}</div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </ModalContent>
            </CustomModal>
        </div>
    );
};

export default ResumeDownloadModal;
