import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import { Grid, Typography } from '@mui/material';
import apiEndPoints from '../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
const { hostname } = apiEndPoints;

const ResumeUpdatesBarChart = () => {
    const chartRef = useRef(null);
    const theme = useTheme();
    const chartInstanceRef = useRef(null);
    const [resumeUpdatedcount, setResumeUpdatedcount] = useState([]);
    const axiosConn = useAxiosInterceptors();
    const secondaryMain = theme.palette.secondary[800];

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Dashboard/ResumeUpdated/Count`)
            .then((response) => {
                console.log('chart', response);
                setResumeUpdatedcount(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        if (Array.isArray(resumeUpdatedcount) && resumeUpdatedcount.length > 0) {
            const date = resumeUpdatedcount.map((data) => data.date);
            const resumeUpdate = resumeUpdatedcount.map((data) => data.resumeUpdate);
            var options = {
                series: [
                    {
                        name: 'Resumes Updated',
                        data: resumeUpdate
                    }
                ],
                chart: {
                    height: 400,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                colors: [secondaryMain],

                xaxis: {
                    categories: date,
                    position: 'bottom'
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return Math.round(val); // Convert the value to an integer for y-axis labels using Math.round()
                        }
                    }
                },
                title: {
                    align: 'center'
                },
                grid: {
                    show: true,
                    borderColor: '#90A4AE',
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                        return val;
                    },
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: undefined
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.9,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                }
            };

            var chart = new ApexCharts(document.querySelector('#chart'), options);
            chart.render();
            chartInstanceRef.current = chart;
        }
    }, [resumeUpdatedcount, secondaryMain]);

    return (
        <MainCard>
            <Grid container justifyContent="right">
                <Grid item width="100%">
                    <Typography variant="h4" textAlign="center">
                        Resumes updated in last 2 weeks
                    </Typography>
                    <div id="chart" style={{ width: '100%', height: '350px' }} ref={chartRef} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ResumeUpdatesBarChart;
