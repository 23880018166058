import SkeletonDomainPieChart from 'views/pages/admin/DashBoard/DomainDonutChart';
import { Box, Typography } from '@mui/material';

const DomainDonutChart = () => {
    return (
        <Box bgcolor="white" sx={{ borderRadius: '1rem' }}>
            <Typography variant="h4" textAlign="center" sx={{ p: 5 }}>
                Top 10 domains used
            </Typography>
            <SkeletonDomainPieChart />
        </Box>
    );
};
export default DomainDonutChart;
