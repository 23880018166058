import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NotFound from 'views/errorResponse/NotFound';
import { useSelector } from 'react-redux';

const BasicInfo = Loadable(lazy(() => import('views/pages/resume-creation/basic-info/BasicInfo')));
const Education = Loadable(lazy(() => import('views/pages/resume-creation/education/Qualification')));
const Abilities = Loadable(lazy(() => import('views/pages/resume-creation/Skills/Abilities')));
const WorkExperience = Loadable(lazy(() => import('views/pages/resume-creation/work-experience/WorkExperience')));
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const Designation = Loadable(lazy(() => import('views/pages/admin/Designation/Designation')));
const AddDesignation = Loadable(lazy(() => import('views/pages/admin/Designation/AddDesignation')));
const UpdateDesignation = Loadable(lazy(() => import('views/pages/admin/Designation/UpdateDesignation')));
const Resume = Loadable(lazy(() => import('views/pages/admin/Resume/Resume')));
const Domain = Loadable(lazy(() => import('views/pages/admin/Domain/Domain')));
const AddDomain = Loadable(lazy(() => import('views/pages/admin/Domain/AddDomain')));
const UpdateDomain = Loadable(lazy(() => import('views/pages/admin/Domain/UpdateDomain')));
const Technology = Loadable(lazy(() => import('views/pages/admin/Technology/Technology')));
const AddTechnology = Loadable(lazy(() => import('views/pages/admin/Technology/AddTechnology')));
const UpdateTechnology = Loadable(lazy(() => import('views/pages/admin/Technology/UpdateTechnology')));
const InhouseProject = Loadable(lazy(() => import('views/pages/admin/Project/Project')));
const AddInhouseProject = Loadable(lazy(() => import('views/pages/admin/Project/AddProject')));
const UpdateInhouseProject = Loadable(lazy(() => import('views/pages/admin/Project/UpdateProject')));
const Role = Loadable(lazy(() => import('views/pages/Role/Role')));
const AddRole = Loadable(lazy(() => import('views/pages/Role/AddRole')));
const UpdateRole = Loadable(lazy(() => import('views/pages/Role/UpdateRole')));
const User = Loadable(lazy(() => import('views/pages/User/User')));
const AddUser = Loadable(lazy(() => import('views/pages/User/AddUser')));
const UpdateUser = Loadable(lazy(() => import('views/pages/User/UpdateUser')));
const Profile = Loadable(lazy(() => import('views/pages/User/Profile')));
const GeneralSetting = Loadable(lazy(() => import('views/pages/admin/Settings/GeneralSetting')));
const TechnologyCategory = Loadable(lazy(()=>import('views/pages/admin/TechnologyCategory/TechnologyCategory')));
const AddTechnologyCategory = Loadable(lazy(()=>import('views/pages/admin/TechnologyCategory/AddTechnologyCategory')));
const UpdateTechnologyCategory = Loadable(lazy(()=>import('views/pages/admin/TechnologyCategory/UpdateTechnologyCategory')));
const Qualification = Loadable(lazy(() => import('views/pages/admin/Qualification/Qualification')));
const AddQualification = Loadable(lazy(() => import('views/pages/admin/Qualification/AddQualification')));
const UpdateQualification = Loadable(lazy(() => import('views/pages/admin/Qualification/UpdateQualification')));
const Template =Loadable(lazy(() => import('views/pages/admin/UploadTemplate/Template')));
const AddTemplate = Loadable(lazy(()=>import('views/pages/admin/UploadTemplate/UploadTemplate')));
const UpdateTemplate =Loadable(lazy(()=>import('views/pages/admin/UploadTemplate/UpdateTemplate')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// ==============================|| MAIN ROUTING ||============================== //
const components = {
    BasicInfo: BasicInfo,
    Education: Education,
    WorkExperience: WorkExperience,
    Designation: Designation,
    AddDesignation: AddDesignation,
    UpdateDesignation: UpdateDesignation,
    Domain: Domain,
    AddDomain: AddDomain,
    UpdateDomain: UpdateDomain,
    Technology: Technology,
    AddTechnology: AddTechnology,
    UpdateTechnology: UpdateTechnology,
    Resume:Resume,
    InhouseProject: InhouseProject,
    AddInhouseProject: AddInhouseProject,
    UpdateInhouseProject: UpdateInhouseProject,
    Role: Role,
    AddRole: AddRole,
    UpdateRole: UpdateRole,
    User: User,
    AddUser: AddUser,
    UpdateUser: UpdateUser,
    Profile: Profile,
    GeneralSetting: GeneralSetting,
    TechnologyCategory:TechnologyCategory,
    AddTechnologyCategory:AddTechnologyCategory,
    UpdateTechnologyCategory:UpdateTechnologyCategory,   
    Qualification: Qualification,
    AddQualification: AddQualification,
    UpdateQualification: UpdateQualification,
    Template:Template,
    AddTemplate:AddTemplate,
    UpdateTemplate:UpdateTemplate,
    Abilities:Abilities

};

const createRoutes = (routerConfig) =>
    new Promise((resolve, reject) => {
        const userroutes = [];
        try {
            routerConfig.forEach((element) => {
                userroutes.push({
                    path: element.path,
                    component: components[element.component]
                });
            });

            resolve(userroutes);
        } catch (e) {
            reject('error while routing');
        }
    });

export { createRoutes };
