const TokenReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_TOKEN_SUCCESS':
            return {
                token: action.payload,
                message: 'Success'
            };
        case 'RESET_TOKEN':
            return {
                token: null,
                message: 'Success'
            };

        default:
            return state;
    }
};

export default TokenReducer;
