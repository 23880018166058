import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Alert, Grid, Snackbar,IconButton,Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, Chip, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import CustomToggleButton from 'ui-component/extended/CustomToggleButton';
import NotFound from 'views/errorResponse/NotFound';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import apiEndPoints from '../../../../constants/api';
const { hostname } = apiEndPoints;

const FileUploadButton = styled('label')({
  display: 'block',
  marginBottom: '10px',
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UpdateTemplate = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [template, setTemplate] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType,setFileType]= useState('');
  const [imageName, setImageName] = useState('');
  const [imageType,setImageType]= useState('');
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [name, setName] = useState(' ');
  const [supportedFileTypes, setSupportedFileTypes] = useState([]);
  const [description,setDescription] = useState('');
  const [errors, setErrors] = useState([]);
  const [nameError, setNameError] = useState('');
  const [supportedFileTypesError, setSupportedFileTypesError] = useState(' ');
  const [descriptionError, setDescriptionError] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const axiosConn = useAxiosInterceptors();
  const token = useSelector((state) => state.tokenReducer.token.accessToken);
  useEffect(() => {
    const GetTemplateById = async () => {
      try {
        const res = await axiosConn.get(`${hostname}/api/v1/Template/OldData/${id}`);
        if (res.data) {
          const templateData = res.data.data;
          console.log('templateData:', templateData);
          setTemplate(templateData.name);
          setName(templateData.name);
          setFileName(templateData.fileName);
          setFileType(templateData.fileType);
          setImageName(templateData.imageName);
          setImageType(templateData.imageType);
          setDescription(templateData.description);
          setSupportedFileTypes(Array.isArray(templateData.supportedFileTypes)
            ? templateData.supportedFileTypes
            : templateData.supportedFileTypes.split(',')); // Convert the string to an array
          console.log('supportedFileTypes:', supportedFileTypes);
        } else {
          setTemplate(undefined);
        }
      } catch (error) {
        setTemplate(undefined);
      }
    };
    GetTemplateById();
  }, []);

  useEffect(() => {
    setErrors([]);
  }, [template,image,file]);

  // const updateTemplate = async (id, name, file,image,supportedFileTypes) => {
  //     try {
  //         if (name.trim() === '') {
  //             setNameError('Template name is required');
  //         } else {
  //             const res = await axiosConn.put(`${hostname}/api/v1/Template/${id}`, {
  //                 id: id,
  //                 Name: name,
  //                 Image: image,
  //                 File:file,
  //                 SupportedFileTypes:supportedFileTypes
  //             });
  //             if (res.data) {
  //               console.log("data",res.data)
  //                 navigate('/domains');
  //             } else {
  //                 if ((res.data.statusCode = 400)) {
  //                     const newErrors = res.data.errors.map((error) => error.message);
  //                     setErrors(newErrors);
  //                 } else {
  //                     setErrors(['Something went wrong']);
  //                 }
  //             }
  //         }
  //     } catch (error) {
  //         if (error.response.data.statusCode == 400) {
  //             const newErrors = error.response.data.errors.map((error) => error.message);
  //             setErrors(newErrors);
  //         } else {
  //             setErrors(['Something went wrong']);
  //         }
  //     }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() === '') {
      setNameError('Template name is required');
      setIsNameValid(false);
    } else if (image && image.size > 1024 * 1024) {
      // Check if the image exists and its size exceeds 1MB
      setImageError('Image size should be less than or equal to 1MB');
    }else if (description.trim()===''){
      setDescriptionError('Description is required');
    }
    else {
      try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Name', template);
        formData.append('File', file);
        formData.append('Image', image);
        formData.append('SupportedFileTypes', supportedFileTypes.join(','));
        formData.append('Description',description)

        const res = await axiosConn.put(`${hostname}/api/v1/Template/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          console.log('data', res.data);
          navigate('/template');
        } else {
          if (res.data.statusCode === 400) {
            const newErrors = res.data.errors.map((error) => error.message);
            setErrors(newErrors);
          } else {
            setErrors(['Something went wrong']);
          }
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.statusCode === 400) {
          const newErrors = error.response.data.errors.map((error) => error.message);
          setErrors(newErrors);
        } else {
          setErrors(['Something went wrong']);
        }
      }
    }
  };
  const options = [
    { value: 'pdf', label: 'pdf' },
    { value: 'docx', label: 'docx' }
  ];
  const handleSupportedfiletype = (event) => {
    const newSupportedfiletype = event.target.value;
    setSupportedFileTypes(newSupportedfiletype);
  }
  const handleChipDelete = (value) => {
    const newSupportedtype = supportedFileTypes.filter((item) => item !== value);
    setSupportedFileTypes(newSupportedtype);

  };
  const downloadTemplate = async () => {
    try {
      const res = await axiosConn.get(`${hostname}/api/v1/Template/FileView/${id}`, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${fileType}`);
      console.log('filename', fileName); // set file name and type
      document.body.appendChild(link);
      link.click();
  } catch (error) {
      console.log('not downloading.....', error);
  }
  };
  const downloadImage = async () => {
    try {
      const res = await axiosConn.get(`${hostname}/api/v1/Template/ImageView/${id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${imageName}.${imageType}`);
      console.log('filename', imageName); // set file name and type
      document.body.appendChild(link);
      link.click();
  } catch (error) {
      console.log('not downloading.....', error);
  }
  };
  const handleDelete = async () => {
    try {
      debugger;
      const response = await axiosConn.delete(`${hostname}/api/v1/Template/${id}`);
      console.log('Template deletion successful:', response.data);
        //handleClose();
        navigate('/template');
        } catch (error) {
      console.error('Error deleting template:', error);
    
    }
  };
  const openConfirmationDialog = () => {
    setOpenDialog(true);
};

const closeConfirmationDialog = () => {
    setOpenDialog(false);
};
const handleTemplateFileChange = (event) => {
  const uploadedFile = event.target.files;
  if (uploadedFile.length === 0) {
    // File selection was canceled, do nothing
    return;
  }
  setFile(uploadedFile[0]);
};

const handleImageFileChange = (event) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) {
    // File selection was canceled, do nothing
    return;
  }
  setImage(uploadedFile);
};
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <Box>
      {template === undefined ? (
        <>
          <NotFound />
        </>
      ) : (
        <Grid container justifyContent="center" mt={15}>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={6}
            component="form"
            onSubmit={handleSubmit}
            sx={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              p: 7,
              bgcolor: 'white'
            }}
          >
            <Typography variant="h2" mb={5}>
              Update : {name}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={template}
              mb={2}
              sx={{ width: '100%' }}
              onChange={(e) => setTemplate(e.target.value)}
              error={!!nameError}
              helperText={nameError}
            />
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FileUploadButton>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleTemplateFileChange}
                      accept=".docx"

                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ color: 'white', width: '100%' }}
                      component="span"
                      startIcon={<CloudUploadIcon />}
                      style={{ textTransform: 'none' }}
                    >
                      Upload new template
                    </Button>
                  </FileUploadButton>
                  {file && (
                    <Box>
                      <Typography variant="body1" mb={2}>
                         {file.name}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: 'white',width:'100%' }}
                    onClick={downloadTemplate}
                    startIcon={<DownloadIcon />}
                    style={{ textTransform: 'none' }}
                  >
                    Existing template
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>

                <FileUploadButton>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    //onChange={(e) => setImage(e.target.files[0])}
                    onChange={handleImageFileChange}
                    accept="image/*"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: 'white',width:'100%' }}
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    style={{ textTransform: 'none' }}
                  >
                    Upload new image
                  </Button>
                </FileUploadButton>
                {image && (
                  <Box>
                    <Typography variant="body1"> {image.name}</Typography>
                  </Box>
                )}
                 {imageError && (
    <Box sx={{ color: '#FF0000', fontSize: '0.8rem', mt: '0.5rem' }}>
      {imageError}
    </Box>
  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: 'white' ,width:'100%'}}
                  onClick={downloadImage}
                  startIcon={<DownloadIcon />}
                  style={{ textTransform: 'none' }}
                >
                  Existing image
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-chip-label">Supported file types</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={supportedFileTypes}
                  label="Supported file types"
                  sx={{ width: '100%' }}
                  onChange={handleSupportedfiletype}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected.map((value) => {
                        const option = options.find((o) => o.value === value);
                        return (
                          <Chip
                            key={value}
                            label={option ? option.label : ''}
                            onDelete={() =>
                              handleChipDelete(value)}
                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          />
                        );
                      })}
                    </Stack>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {options.map(({ value, label }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Enter Description"
              value={description}
              mb={2}
              multiline
              rows={4}
              sx={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
              error={!!descriptionError}
              helperText={descriptionError}
            />
            </Box>
            {/* <Box mt={1}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: 'white' }}
                onClick={downloadTemplate}
                startIcon={<DownloadIcon />}
              >
                <Typography variant="button">Template</Typography>
              </Button>
            </Box> */}
            {/* <Button
    variant="contained"
    color="secondary"
    sx={{ color: 'white' }}
    onClick={downloadImage}
    startIcon={<DownloadIcon />}
  >
    <Typography variant="button">Image</Typography>
  </Button> */}
            {nameError ? (
              <></>
            ) : (
              <>
                {errors.length > 0 && (
                  <Box sx={{ color: '#FF0000', fontSize: '0.8rem', marginLeft: '-4%' }}>
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </Box>
                )}
              </>
            )}
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, gap: 2, mt: 4 }}>
            <Button variant="contained" color="error" sx={{ color: 'white' }} onClick={openConfirmationDialog}>
              Delete
              </Button>
              <Button variant="outlined" color='secondary' sx={{ color: 'secondary' }} onClick={(e) => navigate('/template')}>
                Cancel
              </Button>
              <Button variant="contained" color="secondary" sx={{ color: 'white' }} onClick={handleSubmit}>
                Save 
              </Button>
              <Dialog open={openDialog} onClose={closeConfirmationDialog}>
                                                <DialogTitle>
                                                    <Typography variant="h4" sx={{ fontWeight: 'bolder' }}>
                                                    Delete
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={closeConfirmationDialog}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 8,
                                                            top: 8,
                                                            color: 'grey'
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <Divider />

                                                <DialogContent>Are you sure you want to Delete this Template?</DialogContent>
                                                <DialogActions>
                                                    <Button onClick={closeConfirmationDialog}>Cancel</Button>
                                                    <Button
                                                        onClick={handleDelete}
                                                        // variant="outlined"
                                                        // color="secondary"
                                                        variant="contained"
                                                        color="error"
                                                        sx={{ color: 'white' }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
              {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmation message
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete selected Template
          </Typography>
          <Button variant="contained"  sx={{ color: 'white' }} onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="contained"  sx={{ color: 'white' }} onClick={handleClose}>
                No
              </Button>
        </Box>
      </Modal> */}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UpdateTemplate;
