import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import MainCard from '../../../../ui-component/cards/MainCard';
import apiEndPoints from '../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
const { hostname } = apiEndPoints;

const DesignationPieChart = () => {
  const [designationsUsed, setDesignationsUsed] = useState([]);
  const axiosConn = useAxiosInterceptors();

  useEffect(() => {
    axiosConn.get(`${hostname}/api/v1/Dashboard/DesignationsUsed/Count`)
      .then((response) => {
        console.log('chart', response);
        setDesignationsUsed(response.data.data.designationsUsed);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); // Store the chart instance to remove listeners later

  useEffect(() => {
    // Check if designationPieChart has valid data before rendering the chart
    if (Array.isArray(designationsUsed) && designationsUsed.length > 0) {
      const options = {
        series: designationsUsed.map(data => data.count),
        chart: {
          type: 'pie',
          width: '100%',
          parentHeightOffset: 0,
        },
        labels: designationsUsed.map(data => data.name),
        theme: {
          monochrome: {
            enabled: true,
            color: '#DF2900',
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15,
            },
            size: '70%',
          },
        },
        legend: {
          position: 'bottom',
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
      chartInstanceRef.current = chart;

      return () => {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }
      };
    }
  }, [designationsUsed]);

  return (
    <MainCard>
     
      <div style={{ position: 'relative', width: '100%', height: 0, paddingTop: '100%' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} ref={chartRef} />
      </div>
    </MainCard>
  );
};

export default DesignationPieChart;
