import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import { Grid, Typography } from '@mui/material';
import apiEndPoints from '../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
import MainCard from 'ui-component/cards/MainCard';
const { hostname } = apiEndPoints;

const TechnologybarCart = () => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [technologiesUsed, setTechnologiesUsed] = useState([]);
    const axiosConn = useAxiosInterceptors();

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Dashboard/TechnologiesUsed/Count`)
            .then((response) => {
                console.log('chart', response);
                setTechnologiesUsed(response.data.data.technologiesUsed);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        if (Array.isArray(technologiesUsed) && technologiesUsed.length > 0) {
            const categories = technologiesUsed.map((data) => data.name);
            const data = technologiesUsed.map((data) => data.count);

            const options = {
                series: [
                    {
                        name: 'Technology in use',
                        data: data
                    }
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                        distributed: true,
                        barHeight: '40%',
                        isFunnel: true
                    }
                },
                colors: ['#F44F5E', '#E55A89', '#D863B1', '#CA6CD8'],
                dataLabels: {
                    enabled: false,
                    formatter: function (val) {
                        return Math.round(val);
                    },
                    dropShadow: {
                        enabled: true
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return Math.round(val);
                        }
                    }
                },
                title: {
                    align: 'middle'
                },
                xaxis: {
                    categories: categories,
                    label: {
                        show: false,
                        formatter: function (val) {
                            return Math.round(val);
                        }
                    }
                },
                yaxis: {
                    forceNiceScale: true
                },

                legend: {
                    show: false
                }
            };

            const chart = new ApexCharts(chartRef.current, options);
            chart.render();
            chartInstanceRef.current = chart;
        }
    }, [technologiesUsed]);
    return (
        <Grid container justifyContent="right">
            <Grid item width="100%">
                <div id="chart" style={{ width: '100%', height: '350px' }} ref={chartRef} />
            </Grid>
        </Grid>
    );
};

export default TechnologybarCart;
