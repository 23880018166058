import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React from 'react';

const CustomToggleButton = (props) => {
    const [isActive, setIsActive] = useState();

    useEffect(() => {
        setIsActive(props.status || false);
    }, [props.status]);

    const handleChange = (event) => {
        setIsActive(event.target.checked);
        props.handleToggle(isActive);
    };

    const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.error.main : theme.palette.error.dark
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
            }
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#FF0000' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500
            })
        }
    }));

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: 2,
                    mt: '2px'
                }}
            >
                <Box sx={{ m: 1 }}>
                    <FormControlLabel
                        label={isActive ? 'Active' : 'Active'}
                        labelPlacement="start"
                        control={<IOSSwitch checked={isActive} onChange={handleChange} sx={{ m: 1 }} />}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default CustomToggleButton;
