const initialState = {
    show: false,
    message: ''
};

const ShowMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_MENU':
            return {
                show: action.payload,
                message: 'Success'
            };

        default:
            return state;
    }
};

export default ShowMenuReducer;
