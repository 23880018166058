import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts'; // Import the ApexCharts component
import { Grid } from '@mui/material';
import MainCard from '../../../../ui-component/cards/MainCard';
import apiEndPoints from '../../../../constants/api';
import useAxiosInterceptors from 'views/pages/authentication/auth-forms/AxiosConn';
const { hostname } = apiEndPoints;

const DomainDonutChart = () => {
    const [domainsUsed, setDomainsUsed] = useState([]);
    const axiosConn = useAxiosInterceptors();
    const colors = ['#2196F3', '#C62828', '#69F0AE', '#673AB7'];

    useEffect(() => {
        axiosConn
            .get(`${hostname}/api/v1/Dashboard/DomainUsed/Count`)
            .then((response) => {
                console.log('chart', response);
                setDomainsUsed(response.data.data.domainsUsed);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);


    const options = {
        chart: {
            type: 'donut',
            width: '100%',
            parentHeightOffset: 0,
        },
        labels: domainsUsed.map(data => data.name),
        responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          plotOptions: {
            pie: {
              dataLabels: {
                show:false,
                offset: -1,
              },
              size: '70%',
            },
          },
          legend: {
            position: 'bottom',
          }, dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
                return val
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              padding: 4,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: '#fff',
              opacity: 0.9,
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
              }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
          }
    };

    return (
        <MainCard style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid>
                <div style={{ width: '100%', height: '320px' }}>
                    <ReactApexChart
                        options={options}
                        series={domainsUsed.map((entry) => entry.count)}
                        type="donut"
                        height={300}
                    />
                </div>
            </Grid>
        </MainCard>
    );
};

export default DomainDonutChart;
